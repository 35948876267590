import React from 'react';
import {
    Typography, Grid, TextField, Checkbox, Switch, styled, Button, IconButton, Box,CircularProgress
} from "@mui/material";
import {AppContext} from './AppContext';
import {Link, useLocation} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TaskAddedSuccessDialog from './TaskAddedSuccessDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../styles/style.css";


const PreferenceSwitch = styled(Switch)(() => ({
    width: 34,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 16,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(14px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#000000',
                opacity: 1,
                border: 'none',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        backgroundColor: '#fff',
    },
    '& .MuiSwitch-track': {
        borderRadius: 10,
        backgroundColor: '#808080',
        opacity: 1,
    },
}));

class TaskInputForm extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            taskText: this.props.initialTaskText || "", 
            isSecret: false,
            isBudget: false,
            budgetAmount: "",
            executionPreference: true,
            file: null,
            showAlert: false,
            alertMessageSuccess: false,
            topBarHeight: 0,
            inputFieldHeight: 0,
            isSubmitting: false,

        };
        this.topBar = React.createRef();
        this.inputField = React.createRef();

    }

    componentDidMount() {
        this.updateHeights();
        window.addEventListener('resize', this.updateHeights);
        document.body.style.backgroundColor = 'white';
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeights);
        document.body.style.backgroundColor = '#E8E5E0';
    }

    updateHeights = () => {
        const topBarHeight = this.topBar.current ? this.topBar.current.clientHeight : 0;
        const inputFieldHeight = this.inputField.current ? this.inputField.current.clientHeight : 0;


        this.setState({
            topBarHeight, inputFieldHeight,
        });
    }

    handleChange = (event) => {
        const {name, value, checked, type} = event.target;
        const actualValue = type === "checkbox" ? checked : value;
        this.setState({[name]: actualValue});
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.state.taskText.trim() !== '') {
            this.setState({ isSubmitting: true });
            try {
                // const initialItemCount = this.context.items.length;
                let data = await this.addTask();
                await this.sendMessage(data)
                // await this.waitForNewItem(initialItemCount);
                // this.context.handleTaskAddedSucces(true)
                this.setState({
                    taskText: "", budgetAmount: "", isSecret: false, isBudget: false, showAlert: true, isSubmitting: false
                }, () => {
                });

            } catch (error) {
                this.setState({ isSubmitting: false });
                console.error("Failed to add task:", error);
            }
        }
    }

    bytesToSize = (bytes) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    };

    sendMessage = async (taskID) => {

        try {

            if (!this.state.file) {
                return;
            }

            const familyId = localStorage.getItem('familyID');
            const memberId = localStorage.getItem('memberID');

            let uploadedFile = null;
            let fileMetadata = null;

            if (this.state.file) {
                uploadedFile = await this.context.handleFileUpload(this.state.file);
                const {file} = this.state;
                fileMetadata = JSON.stringify({
                    file_name: file.name, file_type: file.type, file_size: this.bytesToSize(file.size),
                });
            }

            const messageData = {
                type: 'chat_message',
                member_id: memberId,
                family_id: familyId,
                task_id: taskID,
                secret: this.state.isSecret,
                message: '',
                uploaded_file: uploadedFile,
                file_metadata: fileMetadata,
            };


            this.context.ws.send(JSON.stringify(messageData));

            this.setState({file: null})


        } catch (error) {
            console.error("Error sending message:", error);
            this.setState({errorMessage: error.message, isMessageSending: false});
        }
    }

    waitForNewItem = (initialItemCount, retries = 0, maxRetries = 10) => {
        return new Promise((resolve, reject) => {
            const check = async () => {
                try {
                    await this.context.fetchTasks('tasks');
                    if (this.context.items.length >= initialItemCount + 1) {
                        resolve();
                    } else if (retries < maxRetries) {
                        setTimeout(() => {
                            this.waitForNewItem(initialItemCount, retries + 1, maxRetries)
                                .then(resolve)
                                .catch(reject);
                        }, 1000);
                    } else {
                        console.error('Max retries reached without adding a new item.');
                        reject(new Error('Max retries reached without adding a new item.'));
                    }
                } catch (error) {
                    console.error('Error fetching tasks:', error);
                    reject(error);
                }
            };
            check();
        });
    };
    

    addTask() {
        return new Promise((resolve, reject) => {
            const familyID = localStorage.getItem("familyID");
            const memberID = localStorage.getItem("memberID");
            const {taskText, isSecret, budgetAmount, executionPreference} = this.state;
            const actionText = executionPreference ? "Let Me Decide" : "Just Do It";
            fetch("https://hook.eu1.make.com/a0ajuviig6ymkesrj9mes2vl2btut8xk", {
                method: "POST", body: JSON.stringify({
                    "familyID": familyID,
                    "memberID": memberID,
                    "taskText": taskText,
                    "Secret": isSecret,
                    "Budget": budgetAmount,
                    "ExecutionPreference": actionText
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.text();
                })
                .then(data => {     
                    this.setState({alertMessageSuccess:true},()=>{
                        resolve(data);
                    })                  
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    this.setState({alertMessageSuccess:false},()=>{
                        this.setState({showAlert:true})
                    })
                    reject(error);
                });
        });
    }

    handleFileChange = (event) => {
        this.setState({file: event.target.files[0]});
    }

    removeFile = () => {
        this.setState({file: null});
    }


    render() {
        const {
            taskText, isSecret, isBudget, budgetAmount, showAlert, alertMessageSuccess, isSubmitting
        } = this.state;


        const textFieldStyle = {
            borderRadius: 25,
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontFamily: "Manrope",
            fontWeight: "normal",
            flexGrow: 1,
        }

        const buttonStyle = {

            minWidth: 50,
            minHeight: 30,
            background: '#2F98A3',
            borderRadius: "50%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,

        }
        // const letMeDecideText = "I will ask for you to choose options or approve final purchase";
        // const justDoItText = "I will  make the decision to get the task done and make the purchase if applicable";
        return (<>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                overflowY: 'hidden',
                paddingBottom: 'env(safe-area-inset-bottom)'
            }}>

                {isSubmitting && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            zIndex: 1300, // higher than any other z-index
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Box>
                )}

                <TaskAddedSuccessDialog
                    open={showAlert}
                    isSuccess={alertMessageSuccess}
                    onClose={() => this.setState({showAlert: false})}
                />

                <Box>
                    <Grid container ref={this.topBar} alignItems="center" justifyContent="space-between"
                          overflow="hidden">
                        <Grid item xs={1} sm={1} style={{padding: '10px', display: 'flex', justifyContent: 'center'}}>
                            <Link to={`/#`} >
                               <ChevronLeftIcon style={{color: 'black'}}/>
                            </Link>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                </Box>


                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    height: `calc(100vh - ${(this.state.topBarHeight + this.state.inputFieldHeight)}px)`,
                    paddingBottom: `${this.state.inputFieldHeight}px`
                }}>
                    <Grid item xs={12} style={{
                        boxSizing: 'border-box',
                        width: '100%',
                        padding: '10px 20px',
                        justifyContent: 'center',
                        alignItems: 'left',
                        display: 'flex',
                        flexDirection: 'column',

                    }}>

                        <Grid style={{width: '100%',}}>
                            <Typography sx={{
                                color: '#1C1D1F',
                                fontFamily: "'EB Garamond', serif",
                                textAlign: 'left',
                                fontSize: '32px',
                                marginBottom: '10px',
                            }}>How can I help? </Typography>
                        </Grid>

                        {/* To be looked at later */}
                        {/* <Accordion elevation={0} sx={{
                            backgroundColor: '#DBF2F0', borderRadius: '8px', marginBottom: '14px',
                            boxShadow: 'none',
                            border: 'none',
                            '&:before': {
                                display: 'none',
                            }
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="help-content"
                                id="help-header"
                            >
                                <Typography sx={{ fontWeight: '400', color: '#1C1D1F', fontSize:'14px' }}>Help: Want to find out more about how to add a task and what kind of task we can do for you? </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ paddingTop: 0,}}>
                                <Typography sx={{ color: '#1C1D1F', fontSize:'14px'  }}>
                                    Ping your assistant in the chat, and they'll give all the awesome details on how we can help you out!
                                </Typography>
                            </AccordionDetails>
                        </Accordion> */}

                        {/* <Typography sx={{
                            color: '#1C1D1F',
                            fontFamily: "'Open Sans', sans-serif",
                            fontSize: '14px',
                            marginBottom: '10px',
                            fontWeight:600,
                            letterSpacing:'0.1px'
                        }}>
                            Task Title
                        </Typography> */}

                        <TextField
                            style={textFieldStyle}
                            value={taskText}
                            placeholder="Please provide further details for me to complete this task (i.e. time, date, people, sizing etc.) "
                            onChange={this.handleChange}
                            name="taskText"
                            fullWidth
                            multiline
                            minRows={6}
                            maxRows={14}
                            sx={{
                                input: { color: 'black' },

                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#FFFFFF',
                                    borderRadius:'8px',
                                    '& fieldset': {
                                        border: '1px solid #AAAAAA ',

                                    },
                                    '&.Mui-focused fieldset': {
                                        border: '1px solid #AAAAAA ',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: '#1C1D1F', lineHeight: '21px', fontSize: '16px'
                                }
                            }}
                        />
                        <Grid item xs={12} style={{
                            display: 'flex', justifyContent: 'start', textAlign: 'left'
                        }}>
                            <input
                                accept="image/*,.pdf,.doc,.docx,.svg,.png"
                                style={{display: 'none'}}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={this.handleFileChange}
                            />
                            <label htmlFor="raised-button-file">
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    style={{
                                        color: 'white', margin: '20px 0', background:"#1C1D1F", borderRadius:'15px', padding:'12px'
                                    }}
                                >
                                    <AttachFileIcon fontSize="small"/>
                                    <Typography style={{marginLeft: '5px', fontSize:'14px'}}>Attach file</Typography>
                                </IconButton>
                            </label>
                            {this.state.file && (<Typography
                                sx={{color: 'black', marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
                                {this.state.file.name}
                                <IconButton
                                    onClick={this.removeFile}
                                    size="small"
                                    sx={{
                                        color: 'black', marginLeft: '5px',
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Typography>)}
                        </Grid>
                        <Grid item xs={12} style={{
                            display: 'flex', alignItems: 'flex-end',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '25px'
                            }}>

                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Typography sx={{
                                        color: '#1C1D1F',
                                        fontSize: '14px',
                                        fontFamily: "'Open Sans', sans-serif",
                                        fontWeight:600,
                                        fontStyle:'normal',
                                        letterSpacing:'0.1px'
                                    }}>
                                        Treat as a secret task
                                    </Typography>
                                    <Checkbox
                                        checked={isSecret}
                                        onChange={this.handleChange}
                                        name="isSecret"
                                        color="primary"
                                        sx={{
                                            padding:0,
                                            color: 'black',
                                            '&.Mui-checked': {
                                                color: 'black',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '1.8rem',
                                            }
                                        }}
                                    />
                                </Grid>


                                <Grid container alignItems="center" justifyContent="space-between" sx={{ position: 'relative' }}>
                                    <Typography sx={{
                                        color: '#1C1D1F',
                                        fontSize: '14px',
                                        fontFamily: "'Open Sans', sans-serif",
                                        fontStyle:'normal',
                                        fontWeight:600,
                                        letterSpacing:'0.1px'
                                    }}>
                                        Keep a budget?
                                    </Typography>
                                    <PreferenceSwitch
                                    checked={isBudget}
                                    onChange={this.handleChange}
                                    name="isBudget"
                                />
                                </Grid>
                            </Box>
                        </Grid>
                    {isBudget && (<Grid style={{margin: '20px 0px'}}>
                        <TextField
                            placeholder="Enter the amount"
                            value={budgetAmount}
                            onChange={this.handleChange}
                            name="budgetAmount"
                            fullWidth
                            size='small'
                            sx={{
                                input: { color: 'black' },
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#FFFFFF',
                                    borderRadius:'8px',
                                    '& fieldset': {
                                        border: '1px solid #AAAAAA ',

                                    },
                                    '&.Mui-focused fieldset': {
                                        border: '1px solid #AAAAAA ',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: 'black', lineHeight: '2', fontSize: '16px'
                                }
                            }}
                        /></Grid>)}
                    </Grid>




                </div>

                <Box>

                    <Grid ref={this.inputField} container
                          style={{paddingBottom: `env(safe-area-inset-bottom, 15px)`, position: 'fixed', bottom: 0}}>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', padding: 15,}}>
                            <Button
                                style={{
                                    ...buttonStyle,
                                    width: '100%',
                                    height: '50px',
                                    borderRadius: '5px',
                                    textTransform: 'none',
                                    fontWeight:'500',
                                    fontSize: '16px',
                                    color:  isSubmitting || this.state.taskText.trim() === ''  ? '#1C1D1F' : 'white',
                                    backgroundColor: isSubmitting || this.state.taskText.trim() === ''  ? '#B1B1B1' : '#1C1D1F'
                                }}
                                onClick={this.handleSubmit}
                                disabled={isSubmitting || taskText.trim() === ''}
                            > {isSubmitting ? 'Sending...' : 'Send Task'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>)
    }
}

function TaskInputFormWrapper() {
    const location = useLocation();
    
        const queryParams = new URLSearchParams(location.search);
        const taskTextFromUrl = queryParams.get('taskText');
    return <TaskInputForm initialTaskText={taskTextFromUrl} />;
}
export default TaskInputFormWrapper;