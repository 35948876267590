import React, {Component} from 'react';
import AlertDialog from "./AlertDialog"
import {Box, CircularProgress, IconButton, Paper, Typography} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoneIcon from '@mui/icons-material/Done';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaymentDetailsSection from './PaymentDetailsSection';

class TaskCard extends Component {

    constructor(props) {
        super(props);
        this.controlsRef = React.createRef();
        this.circularProgressRef = React.createRef();
        this.state = {
            titleMaxWidth: '250px', anchorEl: null, isReceiptVisible: false,
        };
    }

    componentDidMount() {
        this.updateTitleWidth();
    }

    updateTitleWidth = () => {
        // To be looked at later when the task card  UI is done
        // if (this.controlsRef.current && this.circularProgressRef.current) {
        //     const controlsWidth = this.controlsRef.current.offsetWidth;
        //     const progressWidth = this.circularProgressRef.current.offsetWidth;
        //     const newWidth = window.innerWidth - controlsWidth - progressWidth - 60;
        //     this.setState({ titleMaxWidth: `${newWidth}px` });
        // }
    };



    isCompletedItem = (item) => {
        return (item.includes("Done") || item.includes("Feedback/Payment"));
    };

    isPausedItem = (item) => {
        return (item.includes("Info Needed"));
    };

    isHoldItem = () => {
        return (this.props.isItemHold);
    };

    unreadMessage() {
        return this.props.unreadMessageCount || false;
    }

    handleInTitleUrl = (text) => {
        const linkStyles = {
            wordBreak: 'break-all',
        };
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
    
        let parts = [];
        let subParts = text.split(urlRegex);
        subParts.forEach((subPart, subIndex) => {
            if (urlRegex.test(subPart)) {
                parts.push(<a key={subIndex} href={subPart} style={{...linkStyles, color: '#1C1D1F'}} target="_blank" rel="noopener noreferrer">{subPart}</a>);
            } else {
                parts.push(<span key={subIndex}>{subPart}</span>);
            }
        });
    
        return parts;
    };

    handleRecommendationClick = (recLink) => {
        if (recLink) {
            window.open(recLink, '_blank');
        }
    }
     
    toggleReceiptVisibility = (event) => {
        event.stopPropagation();
        this.setState((prevState) => ({
            isReceiptVisible: !prevState.isReceiptVisible,
        }));
    };

    render() {

        const {chatTask, showControls, isChat} = this.props;
        const { titleMaxWidth, isReceiptVisible  } = this.state;

        const isCompleted = this.isCompletedItem(chatTask.fields["Task - Status Text"]);
        const leftPadding = isCompleted || this.isHoldItem()  ? '8px' : '18px';
    
        const paperStyle = {
            minHeight: 50,
            position: 'relative',
            userSelect: "none",
            margin: '12px 0px',
            padding: `8px 8px 8px ${leftPadding}`,
            background: "#FFFFFF",
            color: "#1C1D1F",
            borderRadius: '8px',
            overflow: 'hidden',
        };

        const isChatWindow= isChat? true : false;

        const chatTaskCardStyle ={
            fontSize:'16px',
            color: "#1C1D1F",
            fontWeight: '600',
            fontFamily: "'Open Sans', sans-serif",
            wordWrap: 'break-word',   
        }

        const listTaskCardStyle ={
            fontSize: '16px',
            color: "#1C1D1F",
            fontWeight: '600',
            fontFamily: "'Open Sans', sans-serif",
            wordWrap: 'break-word',
            overflow: 'hidden',  
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: titleMaxWidth
        }

        const numberStyle = {
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: '20px', 
            backgroundColor: !(this.props.chatTask.fields["Task - Status Text"][0]=== 'In Progress' || chatTask.fields['Task - Status Text'][0] === 'Info Needed' ) ? '#C0BDBD' : '#5BBEA6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        };

   
        const numberTextStyle = {
            fontSize: '14px',
            fontWeight: '500',
            color: '#fff',
              fontFamily: "'Open Sans', sans-serif",
        };

        const progressValue =this.isCompletedItem(chatTask.fields["Task - Status Text"]) ? 100 : Number(chatTask.fields["In Progress Percentage Rollup (from Task - Movement Log)"]
            ?.split(",")
            .map((percent) => (parseFloat(percent.trim()) * 100).toFixed(0))
            .filter((percent) => !isNaN(percent))
            .pop() || 0);

        return (
            <Paper elevation={0} style={paperStyle}>

                {!this.isCompletedItem(chatTask.fields["Task - Status Text"]) && !this.isHoldItem() && (
                    <div style={numberStyle}>
                        <span style={numberTextStyle}>
                            {this.props.index + 1}
                        </span>
                    </div>
                )}
                <Typography component="div" style={{
                    display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%', position: 'relative',
                }}>

            <div id="card-details"
                 style={{display: "flex", alignItems: "center", flex: 1}}>



                <div style={{ display: "flex", alignItems: "left", flex: 1, flexDirection:'column' }}>
                    <div style={{ display: "flex", alignItems: "center", flex: 1, flexDirection: 'row' }}>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.setState({ alertDialogOpen: true, anchorEl: event.currentTarget });
                                    }}
                                >
                                    <MoreVertIcon style={{ color: "#1C1D1F" }} />
                                </IconButton>

                                {this.state.alertDialogOpen && (
                                    <div
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.setState({ alertDialogOpen: false, anchorEl: null });
                                        }}
                                        style={{
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(28, 29, 31, 0.5)',
                                            zIndex: 1300, 
                                        }}
                                    />
                                )}
                                <AlertDialog
                                    open={this.state.alertDialogOpen}
                                    onClose={() => this.setState({ alertDialogOpen: false, anchorEl: null })}
                                    anchorEl={this.state.anchorEl}
                                    itemId={chatTask.id}
                                    handlePin={this.props.handlePin}
                                    handleRemovePin={this.props.handleRemovePin}
                                    isPinned={this.props.isItemPinned}
                                    isHold={this.props.isItemHold}
                                    isInfoNeeded={chatTask.fields['Task - Status Text'][0] === 'Info Needed'}
                                    handleHold={this.props.handleHold}
                                    handleUnhold={this.props.handleUnhold}
                                    maxPinnedItems={this.props.maxPinnedItems}
                                    currentPinnedCount={this.props.currentPinnedCount}
                                    onDeleteTask={this.props.onDeleteTask}
                                    onChildStateChange={this.props.onChildStateChange}
                                    isCompleted={this.isCompletedItem(chatTask.fields["Task - Status Text"])}
                                    index={this.props.index}
                                />
                         <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
                            <div style={isChatWindow ? chatTaskCardStyle : listTaskCardStyle}>
                                {this.handleInTitleUrl(chatTask.fields["Title"])}
                            </div>
                            {/* Add the ChevronRightIcon */}
                            <ChevronRightIcon style={{ color: "#1C1D1F" }} />
                        </div>
                    </div>

                    {chatTask.recLink && (<div style={{marginBottom:'10px'}}>      
                            <span
                                style={{
                                    backgroundColor: '#DBF2F0',
                                    padding: '2px 10px',
                                    borderRadius: '4px',
                                    fontSize: '14px',
                                    fontFamily: "'Open Sans', sans-serif",
                                    fontWeight: '400',
                                    marginLeft:'15px',
                                }}
                                onClick={()=>{this.handleRecommendationClick(chatTask.recLink)}}
                            >
                                Recommendations added
                            </span>
                    </div> )}


                    <div ref={this.circularProgressRef} style={{ paddingLeft: '15px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                {chatTask.fields["In Progress Percentage Rollup (from Task - Movement Log)"] || this.isCompletedItem(chatTask.fields["Task - Status Text"]) ? (
                                    <>
                                        <Box position="relative" display="inline-flex">
                                            <CircularProgress
                                                variant="determinate"
                                                value={100}
                                                size={20}
                                                sx={{
                                                    color: '#C0BDBD',
                                                }}
                                            />
                                            {/* Foreground Progress Circle */}
                                            <CircularProgress
                                                variant="determinate"
                                                value={progressValue}
                                                size={20}
                                                sx={{
                                                    color: '#1C1D1F',
                                                    position: 'absolute',
                                                    left: 0,
                                                }}
                                            />
                                            <Box
                                                top={0}
                                                left={0}
                                                bottom={0}
                                                right={0}
                                                position="absolute"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <DoneIcon
                                                    style={{ fontSize: 12 }}
                                                />
                                            </Box>

                                        </Box>  <Typography style={{ marginLeft: '10px', color: '#555556', fontSize: '14px', fontFamily: "'Open Sans', sans-serif", display: 'inline-flex' }}>

                                        {this.isHoldItem() ? '' : (this.isCompletedItem(chatTask.fields["Task - Status Text"]) ? 'Completed ' : 'In Progress ')}


                                            {progressValue}%

                                            {chatTask.fields['Task - Status Text'][0] === 'Info Needed' && (
                                                <Box sx={{
                                                    color: '#E9722F',
                                                    marginLeft: '10px',
                                                    alignItems: 'center',
                                                    display: 'inline-flex',
                                                    fontFamily: "'Open Sans', sans-serif"
                                                }}>
                                                    <PauseCircleOutlineIcon fontSize="small" sx={{ color: '#E9722F', marginRight: '4px' }} />
                                                    Waiting on you
                                                </Box>

                                            )}

                                            {this.isHoldItem() && (
                                                <span style={{ color: '#E9722F', marginLeft: '10px', alignItems: 'center', display: 'inline-flex' }}>
                                                    <PauseCircleOutlineIcon fontSize="small" sx={{ color: '#E9722F', marginRight: '4px' }} /> Paused
                                                </span>
                                            )}


                                        </Typography>  </>):(<>
                            <Box position="relative" display="inline-flex">
                                            <CircularProgress
                                                variant="determinate"
                                                value={100}
                                                size={20}
                                                sx={{
                                                    color: '#C0BDBD',
                                                }}
                                            />
                                            {/* Foreground Progress Circle */}
                                            <CircularProgress
                                                variant="determinate"
                                                value={progressValue}
                                                size={20}
                                                sx={{
                                                    color: '#1C1D1F',
                                                    position: 'absolute',
                                                    left: 0,
                                                }}/>
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <DoneIcon
                                    style={{ fontSize: 12 }}
/>
                            </Box>
                            
                        </Box>
                        <Typography style={{marginLeft:'10px',color:'#555556', fontSize:'14px',   fontFamily: "'Open Sans', sans-serif", display:'inline-flex'}}>
                           {this.isHoldItem()? "":"To Start "}
                           {progressValue}%

                            {this.isHoldItem() && (
                                <span style={{color:'#E9722F',  marginLeft:'10px',alignItems: 'center', display:'inline-flex'}}>
                                <PauseCircleOutlineIcon fontSize="small" sx={{ color: '#E9722F',  marginRight: '4px' }}/> Paused
                                </span>
                                        )}

                            </Typography>
                        </>

                        )}</div>
                        {showControls && (
                            <div id="card-controls" ref={this.controlsRef} style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', }} >
                                {this.unreadMessage() > 0 && (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '2px 6px',
                                        background: '#FEF3BF',
                                        borderRadius: '4px',
                                    }}>
                                        <img
                                            src={`./app-icons/messageAlert.svg`}
                                            alt=''
                                            />
                                            <span style={{
                                                fontFamily: "'Open Sans', sans-serif",
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                color: '#1C1D1F',
                                                }}>
                                                    {this.unreadMessage()} {this.unreadMessage() === '1' ? 'Message' : 'Messages'}
                                                </span>
                                            </div>
                                        )}


                    
                    {chatTask.fields["txnAmount"] && (
                        <div style={{
                            textAlign: 'center',
                            color: 'rgba(0, 0, 0, 0.5)',
                            fontSize: 14,
                            paddingRight:'6px',
                            fontFamily: "'Open Sans', sans-serif",
                            fontWeight: '500'
                        }}>
                            £{chatTask.fields["txnAmount"]}
                        </div>
                    )}
                </div>
            )}
                    </div>
                    <div>       
                    </div>
                </div>
            </div>
           


        </Typography>
          {chatTask.fields['receipt'] && (
                    <div style={{ marginTop: '4px' }}>
                        <PaymentDetailsSection
                            isVisible={isReceiptVisible}
                            onToggle={this.toggleReceiptVisibility}
                            itemId={chatTask.id}
                            receipt={chatTask.fields['receipt']}
                        />
                    </div>
                )}
        </Paper>);
    }
}

export default TaskCard;