import React, {Component} from 'react';
import {Button, Grid, Box, TextField, FormControl, InputAdornment, CircularProgress, Tab, Tabs, Typography} from "@mui/material";
import ErrorModal from "../ErrorModal";
import ChatOptionsDialog from './ChatOptionsDialog/ChatOptionsDialog';
import ComposeChatDialog from './ComposeChatDialog/ComposeChatDialog';
import MessageEditDialog from './MessageEditDialog/MessageEditDialog';
// import EmojiDialog from "./EmojiDialog/EmojiDialog";
import {AppContext} from '../AppContext';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import {Link, Navigate} from 'react-router-dom';

import '../../styles/style.css'
import { Add, Done } from '@mui/icons-material';

const fileTypeMap = {
    "image/jpeg": 'JPEG',
    "image/jpg": 'JPG',
    "image/png": 'PNG',
    "image/gif": 'GIF',
    "image/webp": 'WEBP',
    "video/mp4": 'MP4',
    "video/3gpp": '3GPP',
    "audio/mpeg": 'MPEG',
    "audio/ogg": 'OGG',
    "audio/opus": 'OPUS',
    "application/pdf": 'PDF',
    "application/msword": 'MSWORD',
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": 'WORD',
    "application/vnd.ms-excel": 'EXCEL',
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": 'EXCEL',
    "application/vnd.ms-powerpoint": 'PPT',
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": 'PPT',
    "text/plain": 'TXT',
    "application/rtf": 'RTF',
    "text/csv": 'CSV',
    "text/vcard": 'VCARD',
    "application/zip": 'ZIP',
};

const MAX_REDIS_CHATS = 30
const MEM_CHAT_PREFIX = "mem_"
class Chat extends Component {

    static contextType = AppContext;
    isClosedChat = () => {
        if (this.props.chatTask && this.props.chatTask.fields) {
            if (this.props.chatTask.fields["Task - Status Text"][0] === 'Done') {
                return this.props.chatTask.fields['Task - Open'][0] === 'Closed';
            }
        }
        return false;
    };

    isSecretChat = () => {
        if (this.props.chatTask && this.props.chatTask.fields) {
            return this.props.chatTask.fields['Secret Task'];
        }
        if (this.state?.taskId && this.state.taskId.startsWith('mem_')) {
            return true;
        }
        return false;
    };


    constructor(props) {
        super(props);
        if (this.props.chatTask) {
            this.state = {
                activeTab: 'general',
                taskId: this.props.chatTask.id ?? null,
                isClosedChat: this.isClosedChat(),
                isSecretTask: this.isSecretChat(),
                members: this.props.members ?? [],
                staff: this.props.staff ?? [],
                userMessage: '',
                chatMessages: [],
                offlineMessages: [],
                currentOffset: 30,
                showOldMessages: false,
                selectedMessage: null,
                editingMessageID:null,
                isChatOptionsOpen: false,
                isComposeChatOpen: false,
                isMessageEditDialogOpen: false,
                isEmojiDialogOpen: false,
                isMessageSending: false,
                isEditingMessage:false,
                errorMessage: null,
                selectedFile: null,
                fileTypeFormatted: '',
                fileType: '',
                fileName: '',
                fileSize: '',
                filePreview: null,
                loadingImages: {},
                sentReadAllAck: false,
                referencedMessage:null,
                image: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
                document: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'text/plain', 'application/rtf', 'text/csv', 'text/vcard', 'application/zip'],
                video: ['video/mp4', 'video/3gpp'],
                audio: ['audio/mpeg', 'audio/ogg', 'audio/opus'],
            }
        }
        this.topBar = React.createRef();
        this.taskCard = React.createRef();
        this.inputField = React.createRef();
        this.chatContainer = React.createRef();
        this.inputTextFieldRef = React.createRef();
        this.messageRefs = {};

        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleMediaOpen = this.handleMediaOpen.bind(this);
        this.handleDocumentOpen = this.handleDocumentOpen.bind(this);
        this.handleCloseComposeChat = this.handleCloseComposeChat.bind(this);
        this.handleAddIconClick = this.handleAddIconClick.bind(this);
        this.handleChatOptionClose = this.handleChatOptionClose.bind(this);
        this.handleSelectMessage = this.handleSelectMessage.bind(this);
        this.handleDeleteMessage = this.handleDeleteMessage.bind(this);
        this.handleCloseMessageEdit = this.handleCloseMessageEdit.bind(this);
        this.handleMessageConfirmation = this.handleMessageConfirmation.bind(this);

    }

    async componentDidMount() {

        document.body.style.backgroundColor = 'white';

        if(this.context?.currentGeneralChatTab ==='secret'){
            this.setState({
                activeTab:'secret'
            })
        }

        this.offlineMessageResendInterval = setInterval(this.attemptResendOfflineMessages, 5000);
        this.requestChatHistory();
        this.updateHeights();
        window.addEventListener('resize', this.updateHeights);
        this.clearGeneralTaskBadge()
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = '#E8E5E0'; 

        clearInterval(this.networkCheckInterval);
        clearInterval(this.offlineMessageResendInterval);
        window.removeEventListener('resize', this.updateHeights);

        this.props.onCloseChat();

        if (this.props.ws) {
            this.props.ws.removeEventListener('message', this.handleMessageConfirmation);
        }
        if (this.state && this.state.filePreview) {
            URL.revokeObjectURL(this.state.filePreview);
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (this.props.chatTask && (this.props.chatTask.id === 'General' || this.props.chatTask.id.startsWith(MEM_CHAT_PREFIX))) {
            
            if (prevState.activeTab !== this.state.activeTab) {
                const memberId = localStorage.getItem('memberID');
                let newTaskId = this.state.activeTab === 'general' ? 'General' : MEM_CHAT_PREFIX + memberId;
                this.context.clearTaskBadges(newTaskId)
                const dataObject = {
                    id: newTaskId,
                };
                
                this.setState({ taskId: newTaskId }, async () => {
                    await this.context.handleCloseChat(true)
                    this.context.setChatTask(dataObject)
                    this.requestChatHistory();
                    this.setState({
                        isSecretTask:this.isSecretChat()
                    })
                    await this.waitForWebSocketOpen();
                    this.sendReadAllAcknowledgement();
                });

            }
        }

        if (prevProps.chatTask?.id !== this.props.chatTask?.id) {
            // this.props.onCloseChat(true)  
            // this.requestChatHistory();
            this.updateHeights();
        }

        if (prevProps.chatMessages !== this.props.chatMessages) {
            if (!this.state.showOldMessages) {
                this.scrollToBottom();
            }
        }

        if (prevState?.offlineMessages?.length !== this.state?.offlineMessages?.length) {
            this.scrollToBottom();
        }

        if (this.props.isWebSocketOpen && !this.state.sentReadAllAck) {
            this.sendReadAllAcknowledgement();
            this.setState({sentReadAllAck: true});
        }


        if (!this.props.isWebSocketOpen) {
            console.error("WebSocket is not connected. Attempting to reconnect from chat...");
            this.props.connectWebSocket && this.props.connectWebSocket();
        }
    }

    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
      };

      clearGeneralTaskBadge = ()=>{
        if(this.props.chatTask && this.props.chatTask.id === 'General'){
           this.context.clearTaskBadges('General')
        }
      }

    attemptResendOfflineMessages = async () => {

        if (this.context.isOnline && this.props.isWebSocketOpen && this.state.offlineMessages.length > 0) {
            let remainingOfflineMessages = [...this.state.offlineMessages];

            for (let i = 0; i < remainingOfflineMessages.length; i++) {
                try {
                    await this.sendMessage(remainingOfflineMessages[i].message);
                    // If message is sent successfully, remove it from the array
                    remainingOfflineMessages.splice(i, 1);
                    i--; // Adjust index since we've modified the array
                } catch (error) {
                    console.error("Error resending offline message:", error);
                    break;
                }
            }

            // Update state with any messages that couldn't be sent
            this.setState({offlineMessages: remainingOfflineMessages});
        }
    }

    waitForWebSocketOpen = async () => {
        while (!this.props.isWebSocketOpen) {
            await new Promise(resolve => setTimeout(resolve, 100)); 
        }
    }


    sendReadAllAcknowledgement = () => {

        const familyId = localStorage.getItem('familyID');

        const all_acknowledgement = {
            type: 'read_all_acknowledgement', family_id: familyId, task_id: this.state.taskId
        };
        const {ws: socket} = this.props;
        socket.send(JSON.stringify(all_acknowledgement));
    };

    updateHeights = () => {

        const topBarHeight = this.topBar.current ? this.topBar.current.clientHeight : 0;
        const taskCardHeight = this.taskCard.current ? this.taskCard.current.clientHeight : 0;
        const inputFieldHeight = this.inputField.current ? this.inputField.current.clientHeight : 0;

        this.setState({topBarHeight, taskCardHeight, inputFieldHeight});
    };

    getNameById = (id, isSentFromAdmin) => {

        const arrayToSearch = isSentFromAdmin ? this.props.staff : this.props.members;
        const entry = arrayToSearch.find(entry => entry && 'ID' in entry && entry.ID === id);
        return entry ? entry.Name : null;
    };

    handleMessageConfirmation = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === 'chat_message' && data.message_id === this.state.messageData.message_id) {
            this.props.ws.removeEventListener('message', this.handleMessageConfirmation);
            // Perform actions after confirmation is received
            this.setState({
                showOldMessages: false,
                selectedFile: null,
                fileName: '',
                fileType: '',
                fileSize: '',
                fileTypeForDisplay: '',
                isMessageSending: false,
                messageData: null,
                referencedMessage:null
            }, () => {
                if (this.fileInput) this.fileInput.value = null;
                this.scrollToBottom();
            });
        }
    }

    handleEditMessageClick = () => {
        this.isEditMessageSend(this.state.userMessage)
            .then(() => {
                this.setState({ userMessage: '' });
            })
            .catch((error) => {
                console.error("Error editing message:", error);
            });
    }

    isEditMessageSend = async(message)=>{
        this.setState({isMessageSending: true});


        try {
            const {ws: socket} = this.props;
            const familyId = localStorage.getItem('familyID');

            const editMessageData = {
                type: 'edit_message',
                family_id: familyId,
                task_id:this.state.taskId,
                new_message: message,
                message_id:this.state.editingMessageID
            };

            await socket.send(JSON.stringify(editMessageData));
        

        } catch (error) {
            console.error("Error editing message:", error);
            this.setState({errorMessage: error.message, isMessageSending: false});
        }
        await this.props.handleEditMessage(this.state.editingMessageID,message)
        this.setState({
            isEditingMessage:false,
            editingMessageID:null,
            isMessageSending: false
        },()=>{
        })
    }

    sendMessage = async (message) => {
        await this.waitForWebSocketOpen();
        this.setState({isMessageSending: true});

        const timestamp = Math.floor(Date.now() / 1000);

        if (!this.context.isOnline) {
            this.setState(prevState => ({
                offlineMessages: [...prevState.offlineMessages, {
                    message, timestamp, isOffline: true
                }],
            }));
            return;
        }

        try {

            const {ws: socket} = this.props;
            const familyId = localStorage.getItem('familyID');
            const memberId = localStorage.getItem('memberID');

            let uploadedFile = null;
            let fileMetadata = null;

            if (this.state.selectedFile) {
                uploadedFile = await this.props.handleFileUpload(this.state.selectedFile);
                const {fileName, fileType, fileSize} = this.state;
                fileMetadata = JSON.stringify({
                    file_name: fileName, file_type: fileType, file_size: fileSize,
                });
            }

            const messageData = {
                type: 'chat_message',
                member_id: memberId,
                family_id: familyId,
                task_id: this.state.taskId,
                secret: this.state.isSecretTask,
                message: message,
                referencedMessage:this.state.referencedMessage? this.state.referencedMessage.ID:null,
                uploaded_file: uploadedFile,
                file_metadata: fileMetadata,
            };


            this.setState({messageData: messageData}, () => {
                this.props.ws.addEventListener('message', this.handleMessageConfirmation);
                socket.send(JSON.stringify(messageData));
            });

        } catch (error) {
            console.error("Error sending message:", error);
            this.setState({errorMessage: error.message, isMessageSending: false});
        }
    }

    requestChatHistory = async () => {

        if (!this.state || this.state.taskId === null) {
            this.context.navigate('/');
            return
        }

        if (this.context.isOnline) {

            await this.context.fetchChatHistory(this.state.taskId)
            this.scrollToBottom();
        }
    }


    requestOlderChatHistory = () => {

        if (this.props.isWebSocketOpen) {
            const socket = this.props.ws;
            const familyId = localStorage.getItem('familyID');
            const memberId = localStorage.getItem('memberID');

            const offset = this.state.currentOffset;
            this.setState(prevState => ({currentOffset: prevState.currentOffset + 30}));
            this.setState({showOldMessages: true})

            const historyRequest = {
                type: 'get_older_history',
                family_id: familyId,
                member_id: memberId,
                task_id: this.state.taskId,
                offset: offset
            };
            socket.send(JSON.stringify(historyRequest));
        }
    };

    scrollToBottom = () => {
        const lastMessageElement = document.querySelector('.message-container:last-child');
        if (!this.chatContainer || !this.chatContainer.current || !lastMessageElement) return;

        const inputFieldHeight = this.inputField.current ? this.inputField.current.clientHeight * 2 : 0;
        const chatContainerHeight = this.chatContainer.current.clientHeight;

        this.chatContainer.current.scrollTop = this.chatContainer.current.scrollHeight - chatContainerHeight + inputFieldHeight;
        lastMessageElement.scrollIntoView({behavior: 'auto'});
    };

    getItemStyle = () => ({
        display:'flex',
        flexDirection:'column',
        width: "100%",
        minHeight: 60,
        userSelect: "none",
        padding:'10px 5px',
        background: "#fff",
        borderRadius:'8px',
        marginBottom:'10px',
        fontFamily: "'Open Sans', sans-serif",
        justifyContent:'center',
        maxHeight:100,
        overflowY:'auto',
        
    });

    handleAddIconClick = () => {
        this.setState({isChatOptionsOpen: true});
    }

    handleChatOptionClose = () => {
        this.setState({isChatOptionsOpen: false});
    }

    bytesToSize = (bytes) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    };

    fileTypeFormatter = (fileType) => {
        return fileTypeMap[fileType] || 'FILE';
    };

    handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({
                selectedFile: file,
                fileTypeFormatted: this.fileTypeFormatter(file.type),
                fileName: file.name,
                fileType: file.type,
                fileSize: this.bytesToSize(file.size),
                filePreview: URL.createObjectURL(file),
                isComposeChatOpen: true,
                isChatOptionsOpen: false,
            });
        }
    };

    handleMediaOpen = () => {
        this.fileInput.accept = 'image/*,video/*,audio/*';
        this.fileInput.click();
    };

    handleDocumentOpen = () => {
        this.fileInput.accept = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.rtf,.csv,.vcf,.zip';
        this.fileInput.click();
    };

    handleCloseComposeChat = () => {
        this.setState({
            isComposeChatOpen: false, selectedFile: null,
        }, () => {
            this.fileInput.value = null;
        });
    }

    handleSelectMessage = (messageData) => {
        this.setState({
            isMessageEditDialogOpen: true, isEmojiDialogOpen: true, selectedMessage: messageData
        });
    }

    handleCloseMessageEdit = () => {
        this.setState({
            isMessageEditDialogOpen: false
        });
    }

    handleReplyMessage = (message) => {
        if (this.state.isEditingMessage) {
            this.setState({
                isEditingMessage: false,
                editingMessageID: null,
                userMessage: '',
            });
        }
        this.setState({referencedMessage:message},()=>{
            this.handleCloseMessageEdit()
        }) 
    }

    handleEditMessage = (message) => {
       this.setState({
        userMessage:message.message,
        isEditingMessage:true,
        editingMessageID:message.ID
       },()=>{
        this.handleCloseMessageEdit()
       })
    }

    handleCloseEmoji = () => {
        this.setState({
            isEmojiDialogOpen: false
        });
    }

    handleEmojiSelect = (emoji, selectedMessageId) => {

        if (this.props.isWebSocketOpen) {

            const socket = this.props.ws;
            const familyId = localStorage.getItem('familyID');
            const memberId = localStorage.getItem('memberID');

            const emojiData = {
                type: 'add_emoji',
                member_id: memberId,
                family_id: familyId,
                emoji: emoji,
                task_id: this.state.taskId,
                message_id: selectedMessageId
            };


            socket.send(JSON.stringify(emojiData));

        }

    };

    getSelectedEmojiForMessage = (messageId) => {
        const message = this.props.chatMessages.find(m => m.ID === messageId);
        return message ? message.emoji : '';
    };

    handleDeleteMessage = (selectedMessageId) => {

        if (this.props.isWebSocketOpen) {

            const socket = this.props.ws;
            const familyId = localStorage.getItem('familyID');
            const memberId = localStorage.getItem('memberID');

            const deleteMessageData = {
                type: 'delete_message',
                member_id: memberId,
                family_id: familyId,
                task_id: this.state.taskId,
                message_id: selectedMessageId
            };


            socket.send(JSON.stringify(deleteMessageData));
            this.props.handleDeleteMessage(selectedMessageId);
            this.setState({
                isMessageEditDialogOpen: false,
            });
            this.scrollToBottom();
        }
    }

    // openImageInNewTab = (imageUrl) => {
    //     window.open(imageUrl, '_blank');
    // };

    handleInMessageUrl = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const newlineRegex = /(\n)/g;

        let parts = [];
        text.split(newlineRegex).forEach((part, index) => {
            if (newlineRegex.test(part)) {
                parts.push(<br key={index}/>);
            } else {

                let subParts = part.split(urlRegex);
                subParts.forEach((subPart, subIndex) => {
                    if (urlRegex.test(subPart)) {
                        parts.push(<a key={`${index}-${subIndex}`} href={subPart} target="_blank"
                                      rel="noopener noreferrer" style={{color: '#1C1D1F'}}>{subPart}</a>);
                    } else {
                        parts.push(<span key={`${index}-${subIndex}`}>{subPart}</span>);
                    }
                });
            }
        });

        return parts;
    };

    handleImageLoaded = (messageId) => {
        this.setState(prevState => ({
            loadingImages: {...prevState.loadingImages, [messageId]: false}

        }), () => {
            if (!this.state.showOldMessages) {
                this.scrollToBottom();
            }
        });
    }

    handleImageError = (messageId) => {
        this.setState(prevState => ({
            loadingImages: {...prevState.loadingImages, [messageId]: true}
        }));
    }

    getMessageById = (id) => {
        return this.context.chatMessages.find(message => message.ID === id) || null;
    }
    

    scrollToMessage = (messageId) => {
        if (this.messageRefs[messageId]) {
            this.messageRefs[messageId].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    render() {

        const {
            chatTask, chatMessages
        } = this.props;

        if (!chatTask) {
            return <Navigate to="/" replace/>;
        }

        const memberId = localStorage.getItem('memberID');
        const secretUnread = Object.entries(this.context.unreadMessageCount)
        .filter(([key]) =>key.includes(`mem_${memberId}`))
        .reduce((sum, [_, value]) => sum + parseInt(value, 10), 0);

        const generalUnread = Object.entries(this.context.unreadMessageCount)
        .filter(([key]) =>key.includes('General'))
        .reduce((sum, [_, value]) => sum + parseInt(value, 10), 0);

        const {
            isChatOptionsOpen,
            isComposeChatOpen,
            isMessageEditDialogOpen, // isEmojiDialogOpen,
            userMessage,
            offlineMessages,
            selectedFile,
            fileName,
            fileType,
            fileSize,
            filePreview,
            fileTypeFormatted,
            image,
            video,
            document,
            activeTab,
        } = this.state;

        const combinedMessages = [...chatMessages, ...offlineMessages];
        const chatMessagesLength = chatMessages.length

        const styles = {

            addIcon: {
                display: 'flex', alignItems: 'center', paddingLeft: 12, paddingRight: 12, height: 48,
            }, textField: {
                background: '#FFFFFF',
                borderRadius: '8px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: "normal",
                fontSize: "clamp(0.6rem, 2vw, 1rem)",
            }, submitButton: {
                minWidth: 48,
                minHeight: 48,
                borderRadius: "50%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 4,
                marginRight: 4,
            }, filePreviewDocument: {
                display: 'flex',
                alignItems: 'center',
                borderRadius: 12,
                width: '100%',
            }, filePreviewDetails: {
                textAlign: 'left',
                color: 'white',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: 'light',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }, filePreviewFileName: {
                color: 'black', fontSize: 16,
            }, filePreviewFileType: {
                color: '#555556', fontSize: 12,
            }, filePreviewFileSize: {
                color: '#555556', fontSize: 12, marginRight: 6,
            },
        };


        const chatMessagesContainerStyle = {
            flex: 1, width: "100%",
        };

        const getMessageStyle = (isSentFromAdmin) => ({
            marginLeft: isSentFromAdmin ? '12px' : 'auto',
            marginRight: isSentFromAdmin ? 'auto' : '12px',
            marginBottom: 12,
            padding: '10px 14px',
            background: isSentFromAdmin ? '#E8E5E0' : '#E8F2F2',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            fontFamily: "'Open Sans', sans-serif",
            wordWrap: 'break-word',
            position: 'relative',
        });

        const inputReferenceMessageStyle ={
            width: '100%', 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            flexDirection: 'column'
        }

        const referencedMessageAttachmentStyles={ 
            width: '100%', 
            borderRadius: '8px', 
            marginTop: '8px', 
            opacity:'0.5',
        }

        const replyAttachmentStyles={ 
            width: '40px', 
            height: '40px', 
            marginRight: '10px', 
            borderRadius: '5px' 
        }

        return (<>
            <div style={{
                display: 'flex', flexDirection: 'column', overflowY: 'hidden',background:'white'
            }}>
                <Grid ref={this.topBar} container alignItems="center" justifyContent="space-between" overflow="hidden">
                    <Grid item xs={1} sm={1} style={{padding: 12, display: 'flex', justifyContent: 'center'}}>
                        <Link to={`/`}> 
                                             <ChevronLeftIcon sx={{color:'#1C1D1F'}} onClick={() => this.props.onCloseChat()}/></Link>
                    </Grid>

                    <Grid item xs={10} sm={10}>
                 
                    {chatTask && (chatTask.id === "General" || chatTask.id.startsWith(MEM_CHAT_PREFIX))?  (
                            <Tabs
                            value={activeTab}
                            onChange={this.handleTabChange}
                            style={{ color: 'white' }}
                            centered
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'black',
        
                                },
                            }}
                        >
                            <Tab value="general" label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                Chat
                                {generalUnread > 0 && (
                                    <span style={{
                                        marginLeft: 8,
                                        background: '#1C1D1F',
                                        borderRadius: '50%',
                                        padding: '2px 6px',
                                        fontSize: '12px',
                                        color: 'white'
                                    }}>
                                        {generalUnread}
                                    </span>
                                )}
                            </div>}

                                style={{ fontSize: '16px', textTransform: 'none', color: '#000000' }} />
                            <Tab value="secret" label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                Private
                                {secretUnread > 0 && (
                                    <span style={{
                                        marginLeft: 8,
                                        background: '#1C1D1F',
                                        borderRadius: '50%',
                                        padding: '2px 6px',
                                        fontSize: '12px',
                                        color: 'white'
                                    }}>
                                        {secretUnread}
                                    </span>
                                )}
                            </div>} style={{ fontSize: '16px', textTransform: 'none', color: '#000000' }} />
                        </Tabs>
                        ) : (
                            <div style={{
                                width: "100%",
                                textAlign: 'center',
                                color: 'white',
                                fontSize: 16,
                                fontFamily: "'Open Sans', sans-serif",
                                fontWeight: '600',
                                wordWrap: 'break-word'
                            }}>
                                Task Chat
                            </div>
                        )}

                    </Grid>

                    <Grid item xs={1} sm={1}></Grid>

                </Grid>

                {!this.context.isOnline && (<div style={{
                    width: "100%",
                    textAlign: 'center',
                    color: 'white',
                    fontSize: 16,
                    fontFamily: "'Open Sans', sans-serif",
                    fontWeight: '600',
                    wordWrap: 'break-word',
                    backgroundColor: 'red'
                }}>
                    You're offline. Messages will be queued for sending once a connection is available.
                </div>)}

                <Grid ref={this.taskCard} container alignItems="center" justifyContent="space-between"
                      overflow="hidden">
                    <div
                        style={{
                            padding: "0 12px",
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            overflow: 'hidden'
                        }}
                    >
                        {chatTask && chatTask.id !== "General" && !chatTask.id.startsWith(MEM_CHAT_PREFIX) && (
                            <>
                            <Box key={0} style={this.getItemStyle()}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={`./app-icons/TaskSingle.svg`} alt=''/>
                                    <div style={{marginLeft:'10px'}}>
                                        <div style={{ fontWeight: 600, fontSize: '16px' }}>{chatTask.fields["Title"]}</div>
                                        <div style={{ fontWeight: 400, fontSize: '15px', color: '#555556', marginTop: '5px' }}>Task Chat</div>
                                    </div>
                                </div>
                              
                            </Box>
                            </>
                        )}
                        
                    </div>
                </Grid>

                {/* New chat main container */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '35px',
                    height: `calc(100vh - ${(this.state.topBarHeight + this.state.taskCardHeight + this.state.inputFieldHeight)}px)`,
                }}>

                    {isComposeChatOpen && (<ComposeChatDialog
                        isComposeChatOpen={isComposeChatOpen}
                        onClose={this.handleCloseComposeChat}
                        userMessage={userMessage}
                        selectedFile={selectedFile}
                        fileName={fileName}
                        fileType={fileType}
                        fileSize={fileSize}
                        filePreview={filePreview}
                        fileTypeFormatted={fileTypeFormatted}
                        sendMessage={this.sendMessage}
                        image={image}
                        video={video}
                    />)}

                    {isMessageEditDialogOpen && <MessageEditDialog
                        selectedMessage={this.state.selectedMessage}
                        isMessageEditDialogOpen={isMessageEditDialogOpen}
                        onClose={this.handleCloseMessageEdit}
                        handleDeleteMessage={this.handleDeleteMessage}
                        handleReplyMessage={this.handleReplyMessage}
                        handleEditMessage={this.handleEditMessage}
                        isChatClosed={this.isClosedChat()}
                    />}

                    {/*{isEmojiDialogOpen && <EmojiDialog*/}
                    {/*    isEmojiDialogOpen={isEmojiDialogOpen}*/}
                    {/*    onClose={this.handleCloseEmoji}*/}
                    {/*    onSelectEmoji={this.handleEmojiSelect}*/}
                    {/*    messageId={this.state.selectedMessage.ID}*/}
                    {/*    selectedEmoji={this.getSelectedEmojiForMessage(this.state.selectedMessage.ID)}*/}
                    {/*/>}*/}

                    {/* Chat messages container */}
                    <div className='chat-messages-container' style={{
                        flex: 1, overflowY: 'auto', scrollbarWidth: "none", msOverflowStyle: 'none',
                    }}>

                         {/* Overlay for editing mode */}
                         {this.state.editingMessageID && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                                zIndex: 2, 
                            }} onClick={e => {  
                                e.stopPropagation();
                                this.setState({ editingMessageID: null, isEditingMessage: false,userMessage:''});
                            }}></div>
                        )}

                        <Grid container>
                            {/* Display received chat messages */}
                            <div
                                ref={this.chatContainer}
                                style={{
                                    height: '100%', width: '100%', display: 'flex', marginBottom: 36,
                                }}
                            >

                                <div style={chatMessagesContainerStyle}>
                                    {chatMessagesLength >= MAX_REDIS_CHATS && (
                                        <div onClick={() => this.requestOlderChatHistory()} style={{
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            background: '#E8E5E0',
                                            borderRadius: 12,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginLeft: 12,
                                            marginRight: 12,
                                        }}>
                                            <div style={{
                                                color: 'rgba(0, 0, 0,)',
                                                fontSize: 14,
                                                fontFamily: "'Open Sans', sans-serif",
                                                fontWeight: '500',
                                                wordWrap: 'break-word',
                                            }}>Fetch Older Messages
                                            </div>
                                        </div>)}
                                    {(() => {
                                        let lastDate = null;
                                        if (!this.context.hasChatLoaded) {
                                            return (<div style={{textAlign: "center", margin: "50px auto"}}>
                                                <CircularProgress style={{color: '#1C1D1F'}}/>
                                            </div>);
                                        }
                                        return combinedMessages.map((messageData, index) => {

                                            if (messageData.hidden) {
                                                return null;
                                            }


                                            const messageTimestamp = messageData.timestamp;
                                            const messageDate = new Date(messageTimestamp * 1000);
                                            const messageDay = messageDate.toDateString();

                                            let file_type = null;
                                            let file_name = null;
                                            let file_size = null;

                                            if (messageData.file_metadata && messageData.file_metadata !== "undefined") {
                                                const fileMetadataObject = JSON.parse(messageData.file_metadata);
                                                file_name = fileMetadataObject?.file_name;
                                                file_type = fileMetadataObject?.file_type;
                                                file_size = fileMetadataObject?.file_size;
                                            }

                                            let displayDate = null;
                                            if (lastDate !== messageDay) {
                                                const today = new Date().toDateString();
                                                const yesterday = new Date(Date.now() - 86400000).toDateString();

                                                if (messageDay === today) {
                                                    displayDate = "Today";
                                                } else if (messageDay === yesterday) {
                                                    displayDate = "Yesterday";
                                                } else {
                                                    displayDate = messageDate.toDateString().slice(0, 3) + " " + messageDate.getDate() + " " + messageDate.toLocaleString('default', {month: 'short'});
                                                }

                                                lastDate = messageDay;
                                            }

                                            const isSentFromAdmin = messageData.user_id;
                                            const relevantId = messageData.user_id || messageData.member_id;
                                            const name = this.getNameById(relevantId, isSentFromAdmin);
                                            const fileTypeForDisplay = this.fileTypeFormatter(file_type);
                                            const messageParts = this.handleInMessageUrl(messageData.message);


                                            return (

                                                <React.Fragment key={index}>

                                                    {displayDate && (

                                                        <div
                                                            key={index}
                                                            style={{
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                margin: 'auto',
                                                                paddingTop: 6,
                                                                paddingBottom: 16,
                                                                paddingLeft: 16,
                                                                paddingRight: 16,
                                                                maxWidth: 150,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    padding:'9px 10px',
                                                                    background: '#E8E5E0',
                                                                    borderRadius: '8px',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    color: '#555556',
                                                                    fontSize: 14,
                                                                    fontFamily: "'Open Sans', sans-serif",
                                                                    fontWeight: '500',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {displayDate}
                                                                </div>

                                                            </div>

                                                        </div>)}
                                                    <div
                                                        key={messageData.ID}
                                                        ref={ref => { this.messageRefs[messageData.ID] = ref }}
                                                        className={`message-container ${isSentFromAdmin ? 'admin' : 'user'}`}
                                                        style={{...getMessageStyle(isSentFromAdmin) ,
                                                            zIndex: messageData.ID === this.state.editingMessageID ? 600 : 1,
                                                        }}
                                                        onClick={(e) => {
                                                            if (e.target.tagName.toLowerCase() === 'a') {
                                                                return;
                                                            }
                                                            this.handleSelectMessage(messageData);
                                                        }}
                                                    >
                                                         {messageData.referencedMessage && this.getMessageById(messageData.referencedMessage) && (
                                                            <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.scrollToMessage(messageData.referencedMessage);
                                                            }}
                                                            style={{
                                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                                width:'100%',
                                                                borderRadius: '3px',
                                                                padding: '8px',
                                                                marginBottom: '8px',
                                                                fontSize: '14px',
                                                                borderLeft: '2px solid black'
                                                            }}>    
                                                                {/* Referenced Message's sender */}
                                                                <div style={{
                                                                    fontSize: '13px',
                                                                    marginBottom: '4px',
                                                                    color: '#555556',
                                                                }}>
                                                                    {(() => {
                                                                        const referencedMsg = this.getMessageById(messageData.referencedMessage);
                                                                        if (!referencedMsg) return 'Unknown Sender';

                                                                        const isAdmin = !!referencedMsg.user_id;
                                                                        const relevantId = referencedMsg.user_id || referencedMsg.member_id;
                                                                        const currentUserId = localStorage.getItem('memberID');

                                                                        // Determine if the sender is the current user
                                                                        const isCurrentUser = relevantId === currentUserId;

                                                                        const senderName = isCurrentUser
                                                                            ? 'You'
                                                                            : this.getNameById(relevantId, isAdmin) || 'Unknown Sender';

                                                                        return senderName;
                                                                    })()}
                                                                </div>                                                           
                                                                {/* For an attachedmnt in the Referenced Message */}
                                                                 {(() => {
                                                                    
                                                                    const referencedMessage = this.getMessageById(messageData.referencedMessage);
                                                                    if (referencedMessage.file_metadata) {
                                                                        
                                                                        const fileMetadata = JSON.parse(referencedMessage.file_metadata);
                                                                        const fileTypeForDisplay = this.fileTypeFormatter(fileMetadata.file_type);

                                                                        if (this.state.image.includes(fileMetadata.file_type)) {
                                                                            return (
                                                                                <img
                                                                                    src={referencedMessage.uploaded_file}
                                                                                    alt={fileMetadata.file_name}
                                                                                    style={referencedMessageAttachmentStyles}
                                                                                />
                                                                            );
                                                                        } else if (this.state.video.includes(fileMetadata.file_type)) {
                                                                            return (
                                                                                <video
                                                                                    controls
                                                                                    src={referencedMessage.uploaded_file}
                                                                                    style={referencedMessageAttachmentStyles}
                                                                                />
                                                                            );

                                                                        } else if (fileTypeForDisplay === 'PDF') {
                                                                            return (
                                                                                <a href={messageData.uploaded_file}
                                                                                    target="_blank" rel="noopener noreferrer"
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        textDecoration: 'none',
                                                                                        color: 'inherit'
                                                                                    }}>
                                                                                    <PictureAsPdfIcon style={{
                                                                                        color: '#555556',
                                                                                        fontSize: 28,
                                                                                        marginRight: 12
                                                                                    }} />
                                                                                    <span style={{ color: '#555556' }}>{fileMetadata.file_name}</span>
                                                                                </a>
                                                                            )
                                                                        } else if (this.state.document.includes(fileMetadata.file_type)) {
                                                                            return (
                                                                                <a
                                                                                    href={referencedMessage.uploaded_file}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        textDecoration: 'none',
                                                                                        color: 'inherit',
                                                                                        marginTop: '8px',
                                                                                        opacity:'0.5' 
                                                                                    }}
                                                                                >
                                                                                    <div style={styles.filePreviewDocument}>
                                                                                        <img
                                                                                            src='./app-icons/document.svg'
                                                                                            alt='Document Icon'
                                                                                            style={{ width: 48, marginRight: 6,  filter: 'invert(1)' }}
                                                                                        />
                                                                                        <div style={styles.filePreviewDetails}>
                                                                                            <span style={styles.filePreviewFileName}>{fileMetadata.file_name}</span>
                                                                                            <div>
                                                                                                <span style={styles.filePreviewFileSize}>{fileMetadata.file_size}</span>
                                                                                                <span style={{ marginRight: 6 }}>&middot;</span>
                                                                                                <span style={styles.filePreviewFileType}>{fileTypeForDisplay}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            );
                                                                        }
                                                                    }
                                                                    return null;
                                                                })()}
                                                                {/* The referencedMessage itself */}
                                                                <div style={{
                                                                    fontStyle: 'italic',
                                                                    color: '#1C1D1F',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {this.getMessageById(messageData.referencedMessage).message}
                                                                </div>
                                                               
                                                            </div>
                                                        )}
                                                        {/*{messageData.emoji && (<div style={{*/}
                                                        {/*    position: 'absolute',*/}
                                                        {/*    top: -20,*/}
                                                        {/*    right: -20,*/}
                                                        {/*    padding: 4,*/}
                                                        {/*    display: 'flex',*/}
                                                        {/*    alignItems: 'center',*/}
                                                        {/*    fontSize: '24px',*/}
                                                        {/*}}>*/}
                                                        {/*    <span>{messageData.emoji}</span>*/}
                                                        {/*</div>)}*/}
                                                        <div
                                                            style={{
                                                                color: isSentFromAdmin ? '#555556' : '#1C1D1F',
                                                                fontWeight: isSentFromAdmin ? 400 : 600,
                                                                fontSize:12,
                                                                width: "100%",
                                                                fontFamily: "'Open Sans', sans-serif",
                                                            }}>

                                                            {name || 'User'}

                                                            <div style={{
                                                                width: '100%',
                                                                color: '#202124',
                                                                fontSize: 14,
                                                                fontWeight: '500',
                                                                position: 'relative',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                paddingBottom: 14,
                                                                paddingTop:5
                                                            }}>

                                                                {/* Embedded attachment */}
                                                                {file_type && (<div style={{margin: '6px 0'}}
                                                                                    onClick={(e) => e.stopPropagation()}>
                                                                    {image.includes(file_type) && (<div
                                                                        // onClick={() => this.openImageInNewTab(messageData.uploaded_file)}
                                                                        >
                                                                        {this.state.loadingImages[messageData.ID] !== false ? (<>
                                                                            <CircularProgress
                                                                                style={{color: 'black'}}/>
                                                                        </>) : null}
                                                                        <img
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                borderRadius: '10px'
                                                                            }}
                                                                            src={messageData.uploaded_file}
                                                                            alt={file_name}
                                                                            onLoad={() => this.handleImageLoaded(messageData.ID)}
                                                                            onError={() => this.handleImageError(messageData.ID)}
                                                                        />
                                                                    </div>)}
                                                                    {video.includes(file_type) && (<div>
                                                                        <video
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                borderRadius: '10px'
                                                                            }}
                                                                            controls
                                                                            src={messageData.uploaded_file}
                                                                        />
                                                                    </div>)}
                                                                    {fileTypeForDisplay === 'PDF' && (
                                                                        <a href={messageData.uploaded_file}
                                                                           target="_blank" rel="noopener noreferrer"
                                                                           style={{
                                                                               display: 'flex',
                                                                               textDecoration: 'none',
                                                                               color: 'inherit'
                                                                           }}>
                                                                            <div style={styles.filePreviewDocument}>
                                                                                <PictureAsPdfIcon style={{
                                                                                    color: 'black',
                                                                                    fontSize: 48,
                                                                                    marginRight: 12,
                                                                                }}/>
                                                                                <div
                                                                                    style={styles.filePreviewDetails}>
                                                                                    <span
                                                                                        style={styles.filePreviewFileName}>{file_name}</span>
                                                                                    <div>
                                                                                        <span
                                                                                            style={styles.filePreviewFileSize}>{file_size}</span>
                                                                                        <span
                                                                                            style={{marginRight: 6}}>&middot;</span>
                                                                                        <span
                                                                                            style={styles.filePreviewFileType}>{fileTypeForDisplay}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>)}
                                                                    {document.includes(file_type) && (
                                                                        <a href={messageData.uploaded_file}
                                                                           target="_blank" rel="noopener noreferrer"
                                                                           style={{
                                                                               display: 'flex',
                                                                               textDecoration: 'none',
                                                                               color: 'inherit'
                                                                           }}>
                                                                            <div style={styles.filePreviewDocument}>
                                                                                <img src='./app-icons/document.svg'
                                                                                     alt='Document Icon' style={{
                                                                                    width: 48, marginRight: 6, filter: 'invert(1)'
                                                                                }}/>
                                                                                <div
                                                                                    style={styles.filePreviewDetails}>
                                                                                    <span
                                                                                        style={styles.filePreviewFileName}>{file_name}</span>
                                                                                    <div>
                                                                                        <span
                                                                                            style={styles.filePreviewFileSize}>{file_size}</span>
                                                                                        <span
                                                                                            style={{marginRight: 6}}>&middot;</span>
                                                                                        <span
                                                                                            style={styles.filePreviewFileType}>{fileTypeForDisplay}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>)}
                                                                </div>)}

                                                                {/* Message Text */}
                                                                <div style={{
                                                                    margin: '0 0 4px 0',
                                                                }}>
                                                                    {messageParts}
                                                                </div>

                                                                <div style={{
                                                                    color: 'rgba(0, 0, 0, 0.50)',
                                                                    fontSize: 12,
                                                                    position: 'absolute',
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    fontFamily: "'Open Sans', sans-serif"
                                                                }}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'right',
                                                                        width: '100%',
                                                                    }}>

                                                                        {new Date(messageData.timestamp * 1000).toLocaleTimeString([], {
                                                                            hour: '2-digit', minute: '2-digit'
                                                                        })}

                                                                        {!isSentFromAdmin && (<div>
                                                                            {messageData.isOffline ? (<img
                                                                                alt="Unsent Message"
                                                                                src="./app-icons/msg-unsent.svg"
                                                                                style={{
                                                                                    left: 5,
                                                                                    bottom: 0,
                                                                                    position: 'relative',
                                                                                }}
                                                                            />) : messageData.timestamp && !messageData.timestamp_read ? (
                                                                                <Done
                                                                                    alt="Message Sent"
                                                                                 
                                                                                    sx={{ color: 'grey' }}
                                                                                    style={{
                                                                                        left: 5,
                                                                                        bottom: 0,
                                                                                        position: 'relative',
                                                                                        fontSize:'16px'
                                                                                    }}
                                                                                />) : messageData.timestamp_read ? (
                                                                                <img
                                                                                alt="Message Read"
                                                                                src="./app-icons/msg-read.svg"
                                                                                style={{
                                                                                    left: 5,
                                                                                    bottom: 0,
                                                                                    position: 'relative',
                                                                                    filter: 'grayscale(100%) brightness(0.6)'
                                                                                }}
                                                                            />) : null}
                                                                        </div>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </React.Fragment>

                                            );
                                        });
                                    })()}

                                </div>
                            </div>
                        </Grid>
                    </div>

                    {this.state.errorMessage && <ErrorModal message={this.state.errorMessage}
                                                            onClose={() => this.setState({errorMessage: null})}/>}

                </div>
                {/* Message Input */}
                <div ref={this.inputField}
                     style={{
                         position: 'fixed',
                         bottom: 0,
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                         width: '100%',
                         backgroundColor: '#555556',
                         padding: '6px 0 12px 0',
                         overflow: 'hidden',
                         paddingBottom: 'env(safe-area-inset-bottom, 12px)',
                         zIndex: this.state.editingMessageID ? 600 : 1,
                     }}>

                    <ChatOptionsDialog
                        isChatOptionsOpen={isChatOptionsOpen}
                        onClose={this.handleChatOptionClose}
                        userMessage={this.state.userMessage}
                        handleMediaOpen={this.handleMediaOpen}
                        handleDocumentOpen={this.handleDocumentOpen}
                    />

                    <div
                        style={{
                            ...styles.addIcon,
                            filter: (!this.props.isWebSocketOpen) ? 'grayscale(100%)' : 'none',
                            opacity: (!this.props.isWebSocketOpen) ? '0.5' : '1',
                            pointerEvents: (!this.props.isWebSocketOpen) ? 'none' : 'auto',
                        }}
                        onClick={this.props.isWebSocketOpen ? () => this.handleAddIconClick() : null}
                    >
                        {!this.state.isClosedChat && (<Add  sx={{ color: 'white', fontSize:'32px' }}/>)}
                    </div>

                    <div ref={this.inputTextFieldRef} style={inputReferenceMessageStyle}>
                        {this.state.referencedMessage && (
                            <div style={{
                                backgroundColor: '#202020',
                                padding: '10px',
                                margin: '10px 0',
                                borderRadius: '15px',
                                width: `${this.inputTextFieldRef.current.clientWidth - 15}px`,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    {(() => {
                                        const referencedMessage = this.getMessageById(this.state.referencedMessage.ID);
                                        if (referencedMessage.file_metadata) {
                                            const fileMetadata = JSON.parse(referencedMessage.file_metadata);
                                            const fileTypeForDisplay = this.fileTypeFormatter(fileMetadata.file_type);

                                            if (this.state.image.includes(fileMetadata.file_type)) {
                                                return (
                                                    <img
                                                        src={referencedMessage.uploaded_file}
                                                        alt={fileMetadata.file_name}
                                                        style={replyAttachmentStyles}
                                                    />
                                                );
                                            } else if (fileTypeForDisplay === 'PDF') {
                                                return (
                                                    <>
                                                        <PictureAsPdfIcon style={{
                                                            color: 'white',
                                                            fontSize: 28,
                                                            marginRight: 12
                                                        }} />
                                                        <Typography style={{ color: 'white' }}>{fileMetadata.file_name}</Typography>
                                                    </>
                                                )
                                            }
                                            else if (this.state.document.includes(fileMetadata.file_type)) {
                                                return (
                                                    <img
                                                        src='./app-icons/document.svg'
                                                        alt='Document Icon'
                                                        style={replyAttachmentStyles}
                                                    />
                                                );
                                            }
                                        }
                                        return null;
                                    })()}
                                    <Typography noWrap style={{ color: 'white' }}>{this.state.referencedMessage.message}</Typography>
                                    <button
                                        style={{ border: 'none', background: 'none', cursor: 'pointer', color: '#666' }}
                                        onClick={() => this.setState({ referencedMessage: null })}
                                    >
                                        <CloseIcon style={{ color: 'white' }} />
                                    </button>
                                </div>
                            </div>
                        )}
                    <TextField

                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                            }, visibility: this.state.isClosedChat ? 'hidden' : 'visible',
                        }}

                        style={{
                            ...styles.textField, pointerEvents: this.state.isClosedChat ? 'none' : 'auto',
                        }}
                        placeholder="Message"
                        value={userMessage}
                        fullWidth
                        multiline
                        variant='outlined'
                        size='small'
                        InputProps={{
                            endAdornment: this.state.isMessageSending ? (<InputAdornment position="end">
                                <CircularProgress size={20} style={{color: '#000000'}}/>
                            </InputAdornment>) : null, style: {color: '#1C1D1F'}
                        }}
                        inputProps={{
                            style: {padding: 0},
                        }}
                        onChange={(e) => this.setState({userMessage: e.target.value})}
                        onKeyDown={(e) => {
                            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

                            if (e.key === 'Enter' && (e.shiftKey || isMobile)) {
                                const currentValue = this.state.userMessage;
                                const selectionStart = e.target.selectionStart;

                                this.setState({
                                    userMessage: currentValue.substring(0, selectionStart) + '\n' + currentValue.substring(selectionStart),
                                });

                                e.preventDefault();
                            } else if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                                if (this.state.userMessage.trim() !== '') {
                                    this.sendMessage(this.state.userMessage).catch((error) => {
                                        console.error("Error sending message:", error);
                                    });
                                    this.setState({userMessage: ''});
                                }
                                e.preventDefault();
                            }
                        }}
                    /></div>


                    <FormControl>
                        <input
                            type='file'
                            id='chat-file-input'
                            style={{display: 'none'}}
                            onChange={this.handleFileSelect}
                            ref={(input) => {
                                this.fileInput = input;
                            }}
                        />
                    </FormControl>

                    {this.state.isEditingMessage && this.state.userMessage !== '' ? (
                        <Button
                            style={{
                                ...styles.submitButton,
                                visibility: 'visible',
                                pointerEvents: 'auto',
                                color: 'white', 
                            }}
                            type="button"
                            onClick={this.handleEditMessageClick}
                        >
                          <CheckIcon/>
                        </Button>
                    ) : this.state.isEditingMessage && this.state.userMessage === '' ? (
                        
                        <Button
                            style={{
                                ...styles.submitButton,
                                visibility: 'visible',
                                pointerEvents: 'auto',
                                color: 'white', 
                            }}
                            type="button"
                            onClick={() => {
                                this.setState({ isEditingMessage: false , editingMessageID:null});
                            }}
                        >
                            <CloseIcon/>
                        </Button>
                    ) : (
                 
                        <Button
                            style={{
                                ...styles.submitButton,
                                visibility: this.state.isClosedChat ? 'hidden' : 'visible',
                                pointerEvents: this.state.isClosedChat ? 'none' : 'auto',
                            }}
                            disabled={userMessage.trim() === ''}
                            type="submit"
                            onClick={() => {
                                if (userMessage.trim() !== '') {
                                    this.sendMessage(userMessage).catch((error) => {
                                        console.error("Error sending message:", error);
                                    });
                                    this.setState({ userMessage: '' });
                                }
                            }}
                        >
                          <ArrowCircleRightOutlinedIcon
                           sx={{
                            filter: !this.props.isWebSocketOpen ? 'grayscale(100%)' : 'none',
                            opacity: !this.props.isWebSocketOpen ? 0.5 : 1,
                            color:'white',
                            fontSize: '32px',
                          }}
                          alt="Send"/>
                        </Button>
                    )}

                </div>
            </div>
        </>)
    }

}

export default Chat;