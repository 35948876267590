// AppContext.js
import React, {createContext, createRef} from 'react';
import {useNavigate} from 'react-router-dom';

export const AppContext = createContext({});

const wssURL = process.env.REACT_APP_WSS_URL;
const secureURL = process.env.REACT_APP_SECURE_URL;
const secureKey = process.env.REACT_APP_UPLOAD_API_KEY;
const baseURL = process.env.REACT_APP_API_BASE_URL;

export class AppContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.navigateRef = createRef();
        this.state = {
            chatTask: null,
            ws: null,
            isWebSocketOpen: false,
            chatMessages: [],
            notificationData: [],
            members: [],
            staff: [],
            hasChatLoaded: false,
            isOnline: navigator.onLine,
            items: [],
            doneItems: [],
            maxActiveTasks: 3,
            hasTasksLoaded: false,
            currentTab: "for you",
            currentGeneralChatTab:null,
            scrollValue: 0,
            unreadMessageCount:{},
            showTaskAddedSuccess:false,
            syncStateWithServer: () => {
            },
            connectWebSocket: this.connectWebSocket,
            setStaffAndMembers: this.setStaffAndMembers,
            handleFileUpload: this.handleFileUpload,
            handleDeleteMessage: this.handleDeleteMessage,
            setChatTask: this.setChatTask,
            handleCloseChat: this.handleCloseChat,
            handleCloseWebSocket: this.handleCloseWebSocket,
            navigate: this.navigate,
            setSyncStateWithServer: this.setSyncStateWithServer,
            updateNotificationBadge: this.updateNotificationBadge,
            fetchChatHistory: this.fetchChatHistory,
            checkNetworkStatus: this.checkNetworkStatus,
            fetchTasks: this.fetchTasks,
            handleDeleteTask: this.handleDeleteTask,
            handleTogglePin: this.handleTogglePin,
            setMaxActiveTasks: this.setMaxActiveTasks,
            handleToggleHold: this.handleToggleHold,
            onDragEnd: this.onDragEnd,
            setCurrentTab: this.setCurrentTab,
            setScrollValue: this.setScrollValue,
            setUnreadCount:this.setUnreadCount,
            handleEditMessage:this.handleEditMessage,
            clearTaskBadges:this.clearTaskBadges,
            setActiveGeneralChatTab:this.setActiveGeneralChatTab,
            fetchSelection:this.fetchSelection,
            clearUnreadMessagesFromServer:this.clearUnreadMessagesFromServer,
            handleTaskAddedSucces:this.handleTaskAddedSucces,
            moveTaskUp:this.moveTaskUp,
            moveTaskDown:this.moveTaskDown
        };
    }

    navigate = (path) => {
        if (this.navigateRef.current) {
            this.navigateRef.current(path);
        } else {
            console.error('Navigate function not available');
        }
    };

    checkNetworkStatus = () => {
        this.handleNetworkStatus();
        this.networkCheckInterval = setInterval(this.handleNetworkStatus, 5000);
    };

    handleNetworkStatus = () => {
        const isOnline = navigator.onLine;
        this.setState({isOnline: isOnline});
    }

    handleTaskAddedSucces =(value)=>{
        this.setState({
            showTaskAddedSuccess:value
        },()=>{
            if (value === true){
                this.navigate('/#')
                this.setCurrentTab('tasks')
                setTimeout(() => {
                    this.setState({ showTaskAddedSuccess: false });
                }, 4000); 
              
            }
        })
    }

    setCurrentTab = (value) => {

        if(value!=='chat'){
        if (this.state.currentTab !== value) {
            this.setState({scrollValue: 0})
        }
        this.setState({currentTab: value})
    }
    }

    setActiveGeneralChatTab = (tab) => {
        return new Promise((resolve) => {
            this.setState({ currentGeneralChatTab: tab }, () => {
                resolve();
            });
        });
    };
    

    setScrollValue = (value) => {
        this.setState({scrollValue: value})
    }


    fetchTasks = async (tab) => {
        const familyID = localStorage.getItem("familyID")
        const memberID = localStorage.getItem("memberID")
        const done = tab === "done" ? 1 : 0;
        const stateKey = done ? 'doneItems' : 'items';

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: "fetchClientTasks", done: done,
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchClientTasks.php`, requestOptions);
            const data = await response.json();
            const {records, txn} = data;

            const tasksNeedingSelection = records.filter((record) => {
                return record.fields["Research Status"] === "Done (Published)";
              });
          
              const selectionTaskIDs = tasksNeedingSelection.map((r) => r.id);
          
              let selectionDataMap = {};
              if (selectionTaskIDs.length > 0) {
                selectionDataMap = await this.fetchSelectionAll(selectionTaskIDs);
              }
            const fetchedTasks = (await Promise.all(records.map(async (record, index) => {
                if (record.fields["Secret Task"] && record.fields["Member"][0] !== memberID) {
                    return null;
                }

                const txnData = txn[record.id];
                if (txnData) {
                    record.fields["txnAmount"] = txnData[0];
                    record.fields["receipt"] = txnData[1];
                }

                if (record.fields["Research Status"] === "Done (Published)") {
                    const shortlink = selectionDataMap[record.id]; 
                    if (shortlink) {
                      record.recLink = shortlink;
                    }
                  }

                record.index = index;
                record.isPinned = (record.fields["Urgent Task"] && record.fields["Task - Status Text"][0] !== "On Hold");
                record.isHold = (record.fields["Task - Status Text"][0] === "On Hold");

                return record;
            }))).filter(record => record !== null);

            this.setState({hasTasksLoaded: true, [stateKey]: fetchedTasks});

        } catch (error) {
            this.setState({hasTasksLoaded: true});
            console.error('Error:', error);
        }
    }

    fetchSelectionAll(taskIDs) {
        return new Promise((resolve, reject) => {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              function: "fetchSelectionAll",
              taskIDs: taskIDs,
            }),
          };
      
          fetch(`${baseURL}/utils/fetchClientTasks.php`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
          
              let shortlinkMap = {};
              if (data && Array.isArray(data.records)) {
                shortlinkMap = data.records.reduce((acc, selRec) => {
                  const taskRecordId = selRec.fields["Task Record Id"];
                  const shortlink = selRec.fields["Shortlink"];
                  if (taskRecordId && shortlink) {
                    acc[taskRecordId] = shortlink;
                  }
                  return acc;
                }, {});
              }
              resolve(shortlinkMap);
            })
            .catch((error) => {
              console.error("Error:", error);
              reject(error);
            });
        });
      }


    // used in fetchTaskByID for notifications
    fetchSelection(taskID) {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: "POST", headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }, body: JSON.stringify({
                    taskID: taskID, function: "fetchSelection",
                }),
            };

            fetch(`${baseURL}/utils/fetchClientTasks.php`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.records && data.records.length > 0) {
                        const selection = data.records[0];
                        resolve(selection.fields["Shortlink"]);
                    } else {
                        resolve(null);
                    }
                }).catch((error) => {
                console.error('Error:', error);
                reject(error);
            });
        });
    }

    handleDeleteTask = (taskId) => {
        const updatedItems = this.state.items.filter((item) => item.id !== taskId);
        this.setState({items: updatedItems});
    };


    saveItemsOrder = (items) => {

        const familyID = localStorage.getItem('familyID');
        fetch(`${baseURL}/utils/saveOrder.php`, {
            method: "POST", body: JSON.stringify({familyID: familyID, items: items}),
        })
            .then((response) => response.text())
            .catch((error) => {
                console.error("There was an error saving the item order:", error);
            });
    };

    setMaxActiveTasks = (maxTasks) => {
        this.setState({maxActiveTasks: maxTasks})
    }


    handleTogglePin = (taskId, isUpgrading) => {
        const {items} = this.state;
        const pinnedItemsCount = items.filter(item => item.isPinned).length;
        const apiUrl = isUpgrading ? `${baseURL}/utils/upgradeClientTask.php` : `${baseURL}/utils/downgradeClientTask.php`;

        const newItems = items.map(item => {
            if (item.id === taskId) {
                if (item.isPinned || pinnedItemsCount < this.state.maxActiveTasks) {
                    item.isPinned = !item.isPinned;
                } else {
                    console.error("Max amount of items pinned.");
                }
            }
            return item;
        });

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8"
            }, body: JSON.stringify({taskId})
        };

        fetch(apiUrl, requestOptions)
            .then(response => {
                if (response.ok) {
                    this.setState({items: newItems});
                    this.saveItemsOrder(newItems);
                } else {
                    throw new Error(`Failed to ${isUpgrading ? 'upgrade' : 'downgrade'} task`);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };


    handleToggleHold = (taskId, isHolding) => {
        const {items} = this.state;
        const apiUrl = `${baseURL}/utils/holdClientTask.php`;
        const newItems = items.map(item => {
            if (item.id === taskId) {
                item.isHold = !!isHolding;
            }
            return item;
        });

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8"
            }, body: JSON.stringify({taskId, isHolding})
        };

        fetch(apiUrl, requestOptions)
            .then(response => {
                if (response.ok) {
                    this.setState({items: newItems});
                } else {
                    throw new Error(`Failed to ${isHolding ? 'hold' : 'unhold'} task`);
                }
            })
            .then(() => {
                this.fetchTasks('Tasks').catch((error) => {
                    console.error("Error fetching tasks:", error);
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    onDragEnd = (result) => {
        if (!result.destination) return;

        const {source, destination} = result;
        const {items} = this.state;

        let newItems = [...items];
        let reorderedItems;
        const isHighPriorityDrag = source.droppableId === "HighPriority" && destination.droppableId === "HighPriority";
        const isNormalPriorityDrag = source.droppableId === "NormalPriority" && destination.droppableId === "NormalPriority";
        const isInfoPriorityDrag = source.droppableId === "InfoPriority" && destination.droppableId === "InfoPriority";

        if (isInfoPriorityDrag ||isHighPriorityDrag || isNormalPriorityDrag) {
            let filterCondition;
            if (isHighPriorityDrag) {
                filterCondition = item => item.isPinned;
            } else if (isNormalPriorityDrag) {
                filterCondition = item => !item.isHold 
                // filterCondition = item => !item.isPinned && item.fields['Task - Status Text'][0] !== 'Info Needed';
            } else if (isInfoPriorityDrag) {
                filterCondition = item => item.fields['Task - Status Text'][0] === 'Info Needed';
            }
            const filteredItems = items.filter(filterCondition);
            reorderedItems = this.reorder(filteredItems, source.index, destination.index);
            
            let j = 0;
            for (let i = 0; i < newItems.length; i++) {
                if (filterCondition(newItems[i])) {
                    newItems[i] = reorderedItems[j];
                    j++;
                }
            }
            newItems.forEach((item, idx) => {
                item.index = idx;
            });

            this.setState({items: newItems});
            this.saveItemsOrder(newItems);
        }
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    moveTaskUp = (taskId) => {
        const { items } = this.state;
        const activeItems = items.filter(item => !item.isHold);
        const index = activeItems.findIndex(item => item.id === taskId);
    
        if (index > 0) {
            const currentItem = activeItems[index];
            const prevItem = activeItems[index - 1];
            const currentIndexInItems = items.findIndex(item => item.id === currentItem.id);
            const prevIndexInItems = items.findIndex(item => item.id === prevItem.id);
    
            const newItems = [...items];
            [newItems[currentIndexInItems], newItems[prevIndexInItems]] = [newItems[prevIndexInItems], newItems[currentIndexInItems]];
    
            // Update indices
            newItems.forEach((item, idx) => {
                item.index = idx;
            });
    
            this.setState({ items: newItems });
            this.saveItemsOrder(newItems);
        }
    };
    

    moveTaskDown = (taskId) => {
        const { items } = this.state;
        const activeItems = items.filter(item => !item.isHold);
        const index = activeItems.findIndex(item => item.id === taskId);
    
        if (index < activeItems.length - 1) {
            const currentItem = activeItems[index];
            const nextItem = activeItems[index + 1];
            const currentIndexInItems = items.findIndex(item => item.id === currentItem.id);
            const nextIndexInItems = items.findIndex(item => item.id === nextItem.id);
    
            const newItems = [...items];
            [newItems[currentIndexInItems], newItems[nextIndexInItems]] = [newItems[nextIndexInItems], newItems[currentIndexInItems]];
    
            // Update indices
            newItems.forEach((item, idx) => {
                item.index = idx;
            });
    
            this.setState({ items: newItems });
            this.saveItemsOrder(newItems);
        }
    };
    

   
    retryCount = 0;
    connectWebSocket = (familyID) => {

        if (this.state.ws === null || this.state.ws.readyState === WebSocket.CLOSED) {
            const socket = new WebSocket(`${wssURL}`);
            const MAX_RETRIES = 25;
            const PING_INTERVAL = 30000; // Ping every 30 seconds to keep alive

            let pingInterval;

            const sendPing = () => {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({type: 'ping'}));
                }
            };

            socket.addEventListener('open', () => {
                this.setState({isWebSocketOpen: true});

                const initMessage = {
                    type: 'init_connection', family_id: familyID,
                };
                socket.send(JSON.stringify(initMessage));
                this.fetchGeneralNotifications()
                pingInterval = setInterval(sendPing, PING_INTERVAL);
            });

            socket.addEventListener('error', console.error);

            socket.addEventListener('close', () => {
                this.setState({isWebSocketOpen: false});
                clearInterval(pingInterval);

                if (this.retryCount < MAX_RETRIES) {
                    console.error('Retrying connection to ws. ' + this.retryCount);
                    this.retryCount++;
                    setTimeout(() => this.connectWebSocket(familyID), 5000);
                } else {
                    console.error('Dead Connection');
                }
            });

            socket.addEventListener('message', (event) => {

                try {
                    const messageData = JSON.parse(event.data);
                    switch (messageData.type) {
                        case 'chat_message':
                            this.handleChatMessage(messageData);
                            break;
                        case 'chat_history':
                            this.handleChatHistory(messageData);
                            break;
                        case 'updated_chat_history':
                            this.handleUpdatedChatHistory(messageData);
                            break;
                        case 'mark_as_read':
                            this.handleMarkMessageAsRead(messageData.message_id);
                            break;
                        case 'update_unread_count':
                            this.updateUnreadMessagesCount(messageData);
                            break;
                        case 'notification':
                            this.handleNotification(messageData);
                            break;
                        case 'notification_history':
                            this.handleNotificationHistory(messageData)
                            break;
                        case 'notification_delete':
                            this.handleNotificationDelete(messageData.notification_id)
                            break;
                        case 'pong': // message types the client side can ignore
                        case 'admin_update_unread_count':
                        case 'admin_mark_as_read':
                        case 'admin_notification_mark_as_read':
                            break;
                        default:
                            console.error('Unhandled message type:', messageData.type);
                            break;
                    }
                } catch (error) {
                    console.error('Error parsing message:', error);
                }
            });

            this.setState({ws: socket});
        }
    };

    fetchGeneralNotifications = async () => {

        const socket = this.state.ws;
        const familyId = localStorage.getItem('familyID');
        const notificationRequest = {
            type: 'get_notification', family_id: familyId,
        };
        socket.send(JSON.stringify(notificationRequest));

    }

    handleChatMessage = (messageData) => {
        const currentChat = this.determineCurrentChat();
        if (currentChat && messageData.task_id === currentChat.id) {
            this.updateChatMessages(messageData);
            if (messageData.user_id) { // Only mark the message as read if it's come from the Assistant
                this.sendReadAcknowledgement(messageData);
            }
        } else {
            this.state.syncStateWithServer(); // Fetch the unread counts
        }
    };

    determineCurrentChat = () => {
        return this.state.chatTask ? this.state.chatTask : null;
    };

    updateChatMessages = (messageData) => {
        this.setState(prevState => ({
            chatMessages: [...prevState.chatMessages, messageData]
        }));
    };

    handleNotification = (messageData) => {
        this.updateNotification(messageData);

    };
    handleNotificationHistory = (messageData) => {
        try {
            const incomingNotifications = messageData.notification.reverse();
            this.setState({
                notificationData: incomingNotifications,
            });
        } catch (error) {
            console.error("Error parsing chat history:", error);
        }

    };
    updateNotification = (messageData) => {
        this.setState(prevState => ({
            notificationData: [messageData, ...prevState.notificationData]
        }));
    };
    updateNotificationBadge = (updatedData) => {
        this.setState({notificationData: updatedData});
    };

    updateUnreadMessagesCount = (messageData) => {
        this.setState(prevState => {

            let updatedCount = {...prevState.unreadMessageCount, [messageData.task_id]: messageData.unread_count};

            return {unreadMessageCount: updatedCount};
        });
    };

    setUnreadCount =(count) =>{
        this.setState({unreadMessageCount:count})
    }

    clearTaskBadges = (taskID) => {
        return new Promise((resolve, reject) => {
            this.setState(prevState => {
                const updatedCount = {...prevState.unreadMessageCount};
                delete updatedCount[taskID];

                const { items, doneItems } = prevState;
                const allItems = [...(items || []), ...(doneItems || [])];
                // Calculate total unread messages after removing the count for the clicked task
                const totalUnreadMessages = Object.entries(updatedCount).reduce((acc, [id, count]) => {
                    if (
                        (allItems.some(item => item.id === id)) ||
                        id === 'General' ||
                        id.startsWith('mem_')
                    ) {
                        return acc + Number(count);
                    }
                    return acc;
                }, 0);

                this.clearAppBadge(totalUnreadMessages);

                this.clearUnreadMessagesFromServer(taskID).catch((error) => {
                    console.error("Error clearing messages from server:", error);
                });

                return {unreadMessageCount: updatedCount};
            });
            resolve();
        });
    };

    clearUnreadMessagesFromServer = async (taskID) => {
        const familyId = localStorage.getItem('familyID');
        try {
            const response = await fetch(`${baseURL}/utils/pushNotifications.php?action=clearUnreadMessages`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }, body: `familyID=${familyId}&taskID=${taskID}`,
            });

            await response.json();
        } catch (error) {
            console.error('Error clearing unread messages:', error);
        }
    };

    clearAppBadge = (totalUnreadMessages) => {
        if (!('setAppBadge' in navigator)) return;

        if (totalUnreadMessages > 0) {  
            navigator.setAppBadge(totalUnreadMessages).catch(error => {
                console.error("Failed to set app badge:", error);
            });  
        }  else if(totalUnreadMessages === 0){
            navigator.clearAppBadge()    
        } else {
            navigator.clearAppBadge().catch(error => {
                console.error("Failed to clear app badge:", error);
            });
        }
    };

    sendReadAcknowledgement = (messageData) => {
        const acknowledgement = {
            type: 'read_acknowledgement',
            message_id: messageData.ID,
            family_id: messageData.family_id,
            task_id: messageData.task_id
        };
        this.state.ws.send(JSON.stringify(acknowledgement));
    };

    fetchChatHistory = (taskID) => {

        this.setState({hasChatLoaded: false})
        return new Promise((resolve, reject) => {
            const familyId = localStorage.getItem('familyID');

            const requestData = {
                familyID: familyId, taskID: taskID,
            };
            fetch(`${baseURL}/utils/fetchChatHistory.php?action=fetchChatHistory`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Accept': 'application/json',
                }, body: JSON.stringify(requestData)
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then(data => {
                    this.setState(prevState => ({
                        chatMessages: [...data, ...prevState.chatMessages],
                    }), () => {

                        const checkArrays = () => {
                            if (this.state.staff.length === 0 && this.state.members.length === 0) {
                                setTimeout(checkArrays, 50);
                            } else {
                                this.setState({hasChatLoaded: true}, () => {
                                    resolve();
                                });
                            }
                        };
                        checkArrays();
                    });
                })
                .catch(error => {
                    console.error('There was a problem with your fetch operation:', error);
                    reject(error);
                });
        });

    }

    handleChatHistory = (messageData) => {

        try {
            const incomingMessages = messageData.history;
            this.setState(prevState => ({
                chatMessages: [...incomingMessages, ...prevState.chatMessages],
            }));
        } catch (error) {
            console.error("Error parsing chat history:", error);
        }
    };


    handleUpdatedChatHistory = (messageData) => {
        try {
            const incomingMessages = messageData.history;
            this.setState({
                chatMessages: incomingMessages,
            });
        } catch (error) {
            console.error("Error parsing chat history:", error);
        }
        this.state.syncStateWithServer();
    };


    handleMarkMessageAsRead = (messageID) => {
        let chatMessages = [...this.state.chatMessages];
        let messageIndex = chatMessages.findIndex(message => message.ID === messageID);

        const isMessageFound = messageIndex !== -1;
        if (isMessageFound) {
            chatMessages[messageIndex].timestamp_read = new Date().toISOString();
            this.setState({chatMessages: chatMessages});
        }

    };

    handleCloseChat = async (keepChatTask = false) => {
        return new Promise((resolve) => {
            if (keepChatTask) {
                // this is used to clear chat messsage, used for switching between secret and general chat
                this.setState({ chatMessages: [] }, resolve);
            } else {
                // used to clear  the chat task as well for example moving out of the chat tab/ task chat
                this.setState({ chatTask: null, chatMessages: [] }, resolve);
            }
        });
    }    

    handleDeleteMessage = (messageId) => {
        const updatedMessages = this.state.chatMessages.filter((message) => message.ID !== messageId);
        this.setState({chatMessages: updatedMessages});
    };

    handleEditMessage = (messageId, newMessage) => {
        this.setState((prevState) => {
            const updatedMessages = prevState.chatMessages.map((message) => {
                if (message.ID === messageId) {
                    return { ...message, message: newMessage }; 
                }
                return message; 
            });
            return { chatMessages: updatedMessages }; 
        });
    };

    handleNotificationDelete = (notificationId) => {
        const updatedNotifications = this.state.notificationData.filter((notification) => notification.ID !== notificationId);
        this.setState({notificationData: updatedNotifications});

    };

    handleFileUpload = (file) => {

        return new Promise(async (resolve, reject) => {

            const familyId = localStorage.getItem('familyID');
            const formData = new FormData();
            formData.append('file', file);
            formData.append('familyid', familyId);

            try {

                const response = await fetch(`${secureURL}/fileupload/receiveFile.php`, {
                    method: 'POST', headers: {
                        'Authorization': `Bearer ${secureKey}`
                    }, body: formData,
                })

                const data = await response.json();

                if (data.success) {

                    const filePathBase64 = data.path;
                    const filePath = atob(filePathBase64);
                    const filePreviewForChat = `${secureURL}/fileupload/${filePath}`;

                    return resolve(filePreviewForChat);
                } else {
                    let error = data.message ?? "File upload failed. Please try again later";
                    reject(new Error(error));
                }
            } catch (error) {
                console.error(error);
                reject(error);
            }

        });
    }

    setStaffAndMembers = (staff, members) => {
        this.setState({
            staff: staff, members: members
        })

    }

    setChatTask = async (task) => {
        await new Promise((resolve) => {
            this.setState(
                { chatTask: task },
                () => {
                    this.retryCount = 0;
                    resolve();
                }
            );
        });
    }
    
    


    handleCloseWebSocket = () => {

        if (this.state.ws) {
            this.state.ws.close();
        }

    }

    setSyncStateWithServer = (syncStateWithServer) => {

        this.setState({
            syncStateWithServer: syncStateWithServer,
        })

    }

    render() {
        return (<AppContext.Provider value={this.state}>
            {this.props.children}
        </AppContext.Provider>);
    }
}

export const AppContextProviderWithNavigate = (props) => {
    const navigate = useNavigate();
    return (<AppContextProvider {...props} ref={(instance) => {
        if (instance) {
            instance.navigateRef.current = navigate;
            instance.state.navigate = navigate;
        }
    }}/>);
};
