/* global PushNotification, universalLinks */
import React from "react";
import packageInfo from '../package.json';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import {createRoot} from "react-dom/client";
import MyTabs from './components/Tabs';
import Referral from './components/Referral';
import ChatWrapper from './components/ChatSystem/ChatWrapper';
import TaskInputFormWrapper  from './components/TaskInputForm';
import Notifications from "./components/Notifications.js";
import {CircularProgress, Grid, ThemeProvider, Box} from "@mui/material";
import "@fontsource/manrope";
import "./styles/style.css";
import MUIStyles from './styles/MUIStyles';
import {AppContext, AppContextProviderWithNavigate} from './components/AppContext.js';
import TagManager from 'react-gtm-module'
import Locker from "./components/DigitalLocker/DigitalLocker.js";
import Login from "./components/LoginAndSignUp/Login.js";
import SignUp from "./components/LoginAndSignUp/SignUp.js";
import ForYou from "./components/ForYou.js";
import TaskPage from "./components/TaskPage.js";
import MyAccountPage from "./components/MyAccountPage.js";
import TaskOverview from "./components/TaskOverview.js";

const tagManagerArgs = {
    gtmId: 'GTM-WSFQ8RMW'
}

TagManager.initialize(tagManagerArgs)
const baseURL = process.env.REACT_APP_API_BASE_URL;

// const styles = {
//     root: {
//         backgroundColor: "#121214",
//     }, container: {
//         display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh',
//     }, formSection: {
//         flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
//     }, exploreLinkSection: {
//         flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
//     }, tilesContainer: {
//         display: 'flex', justifyContent: 'space-between', width: '100%',
//     }, tile: {
//         flex: 1, margin: '5px', padding: '20px', backgroundColor: '#f0f0f0', color: '#333', textAlign: 'center',
//     }, footerSection: {
//         flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
//     }, form: {
//         width: '100%', padding: 12, display: "flex", alignItems: "center",
//     }, link: {
//         color: 'white'
//     }, textField: {
//         background: '#202020',
//         borderRadius: 25,
//         overflow: 'hidden',
//         border: '0.50px #48484D solid',
//         justifyContent: 'flex-start',
//         alignItems: 'center',
//         gap: 8,
//         display: 'inline-flex',
//         fontFamily: "Manrope",
//         fontWeight: "normal",
//         fontSize: "clamp(.6rem, 2vw, 1rem)",
//         flexGrow: 1,
//     }, submitButton: {
//         minWidth: 50,
//         minHeight: 50,
//         background: '#2F98A3',
//         borderRadius: "50%",
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         gap: 8,
//         marginLeft: "8px",
//     },  priorityText: {
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         color: "#fff",
//         paddingBottom: "12px",
//         paddingTop: "12px",
//         fontFamily: "Manrope",
//         fontSize: 16,
//     },
// };

class RoutingApp extends React.Component {
    render() {
        return (<Router>
            <AppContextProviderWithNavigate>
                <Routes>
                    <Route path="/" element={<App tab="tasks"/>}/>
                    <Route path="/chat" element={<ChatWrapper/>}/>
                    <Route path="/refer" element={<Referral/>}/>
                    <Route path="/notifications" element={<Notifications/>}/>
                    <Route path="/newTask" element={<TaskInputFormWrapper/>}/>
                    <Route path="/taskOverview" element={<TaskOverview/>}/>
                    <Route path="/locker" element={<Locker/>}/>
                    <Route path="/signUp" element={<SignUp/>}/>
                </Routes>
            </AppContextProviderWithNavigate>
        </Router>);
    }
}

class App extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.pinnedListRef = React.createRef();

        const token = localStorage.getItem('token');
        const isAuthenticated = !!token;

        this.state = {
            appVersion: packageInfo.version,
            memberEmail: '',
            password: '',
            newPassword: '',
            confirmPassword: '',
            authErrorMessage: '',
            noPassword: true,
            authenticated: isAuthenticated,
            ws: null,
            isWebSocketOpen: false,
            isChatOpen: false,
            chatMessages: [],
            // unreadMessageCount: {},
            timerProgress: 0,
            deferredPrompt: null,
            items: [],
            doneItems: [],
            familyID: localStorage.getItem("familyID") || null,
            updatedFamilyID: '',
            memberID: localStorage.getItem("memberID") || null,
            members: [],
            staff: [],
            maxActiveTasks: 3,
            tab: "tasks",
            showContent: {},
            filteredCategory: "All Categories",
            selectedTask: null,
            replacementButtonVisible: false,
            taskToBePinned: null,
            searchQueryTasks: '',
            searchQueryDone: '',
            dialogPosition: {
                top: 0, left: 0, width: 0, height: 0
            },
            swapDialogOpen: false,
            hasFamilyDataLoaded: false,
            hasMembersDataLoaded: false,
        };

        this.scrollableDivRef = React.createRef();
        this.bottomMenuRef = React.createRef();
        this.gridContainerRef = React.createRef();


        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleNotification = this.handleNotification.bind(this);
    }

    componentDidMount() {
        this.updateGridMargin();
        this.setState({tab: this.context.currentTab})
        if (this.scrollableDivRef.current) {
            this.scrollableDivRef.current.scrollTop = parseInt(this.context.scrollValue, 10);
        }
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.context.checkNetworkStatus();

        const {familyID} = this.state;
        if (familyID) {
            localStorage.setItem("familyID", familyID);
            this.performFamilyIDDependentActions(familyID);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.familyID !== this.state.familyID && this.state.familyID) {
            this.performFamilyIDDependentActions(this.state.familyID);
        }
        if (prevState.tab !== this.state.tab) {
            if (this.scrollableDivRef.current) {
                this.scrollableDivRef.current.scrollTop = 0;
            }
            this.setState({searchQueryTasks:'',searchQueryDone:''})
        }
        this.updateGridMargin();

    }


    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        if (this.scrollableDivRef.current) {
            this.context.setScrollValue(this.scrollableDivRef.current.scrollTop)
        }

        if (this.state.ws) {
            this.state.ws.close();
        }

    }

    updateGridMargin = () => {
        if (this.bottomMenuRef.current) {
            const bottomMenuHeight = this.bottomMenuRef.current.clientHeight;
            if (this.gridContainerRef.current) {
                this.gridContainerRef.current.style.marginBottom = `calc(${bottomMenuHeight}px + env(safe-area-inset-bottom) + 12px)`;
            }
        }
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            this.syncStateWithServer();
        }
    }

    async performFamilyIDDependentActionsForNotifications(familyID, taskID) {
    
        this.getFamilyData(familyID);
        if (this.state.authenticated) {
            this.initPushNotifications();
            this.syncStateWithServer();
            if(taskID === 'General' || taskID.startsWith('mem_')){
                await this.context.fetchTasks('tasks').catch((error) => {
                    console.error("Error fetching tasks:", error);
                });
            }
            this.context.connectWebSocket(familyID);
            this.context.setSyncStateWithServer(this.syncStateWithServer);
        }
    }
    

    async handleNotification(data) {
        const taskID = data.additionalData.chatID;
        const foreground = data.additionalData.foreground;

        if (taskID && !foreground) {
            const familyID = localStorage.getItem('familyID');
            await this.performFamilyIDDependentActionsForNotifications(familyID, taskID);
            this.handleTaskClickByID(taskID).then().catch((error) => console.error(error));
        }
    }

    initPushNotifications() {
        if (window.cordova) {
            const push = PushNotification.init({
                android: {
                    senderID: "218275581837", clearBadge: true, clearNotifications: true
                }, ios: {
                    alert: "true", badge: true, clearBadge: true, sound: "true"
                }
            });

            push.on('registration', async function (data) {
                const familyID = localStorage.getItem('familyID');
                const memberID = localStorage.getItem('memberID');
                const userAgent = navigator.userAgent;

                const registrationId = data.registrationId;
                const registrationType = data.registrationType;

                if (familyID && memberID && registrationId && registrationType) {
                    try {
                        await fetch(`${baseURL}/utils/pushNotifications.php?action=subscribe`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            body: `familyID=${familyID}&memberID=${memberID}&registrationId=${registrationId}&registrationType=${registrationType}&userAgent=${encodeURIComponent(userAgent)}`
                        });
                        console.log('Cordova registration token sent to server.');
                    } catch (error) {
                        console.error("Error in Cordova registration:", error);
                    }
                }
            });

            push.on('notification', this.handleNotification);

            push.on('error', function (e) {
                console.error('Received an error' + e);
            });
        }
    }

    syncStateWithServer = () => {
        const {familyID, memberID, appVersion} = this.state;

        fetch(`${baseURL}/utils/pushNotifications.php?action=getUnreadCounts`, {
            method: 'POST', headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }, body: `familyID=${familyID}&memberID=${memberID}&app_version=${appVersion}`,
        }).then(response => response.json())
            .then(data => {
                this.context.setUnreadCount(data.unreadMessageCount)
            })
            .catch(error => {
                console.error('Error fetching unread counts:', error);
            });
    }




    logout = () => {
        this.setState({memberID: null});
        this.setState({familyID: null});
        this.setState({noPassword: true});
        this.setState({authenticated: false});
        this.setState({authErrorMessage: ''});
        this.setState({memberEmail: ''});
        this.setState({password: ''});
        this.setState({newPassword: ''});
        this.setState({confirmPassword: ''});

        localStorage.removeItem('memberID');
        localStorage.removeItem('familyID');
        localStorage.removeItem('token');
    };

    newTask = () => {
        this.context.navigate('/newTask');
    };

    logClientAction(familyID, action) {
        fetch(`${baseURL}/utils/clientActions.php`, {
            method: "POST", body: JSON.stringify({familyID: familyID, action: action}),
        })
            .then((response) => {
                return response.text();
            })
            .then()
            .catch((error) => console.error(error));
    }






    async performFamilyIDDependentActions(familyID) {

        this.getFamilyData(familyID);
        if (this.state.authenticated) {
            await this.performAuthenticatedActions(familyID)
        }

    }

    async performAuthenticatedActions(familyID) {

        this.initPushNotifications();
        this.syncStateWithServer();
        await this.context.fetchTasks('tasks').catch((error) => {
            console.error("Error fetching tasks:", error);
        });
        this.context.fetchTasks('done').catch((error) => {
            console.error("Error fetching tasks:", error);
        });
        this.context.connectWebSocket(familyID);
        this.context.setSyncStateWithServer(this.syncStateWithServer)


    }



    async fetchMembers(familyID) {
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: 'fetchMemberID',
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions);
            return await response.json();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    async fetchStaff() {
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                function: 'fetchStaff',
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions);
            return await response.json();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    getFamilyData(familyID) {

        this.setState({hasFamilyDataLoaded: false})

        let assistantID = null;
        let maxActiveTasks = 3;
        let members = [];
        let staff = [];

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: "fetchFamilyDataSlice",
            }),
        };


        fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions)
            .then((response) => response.json())
            .then((data) => {

                this.setState({
                    assistantID: data["Assistant"],
                    maxActiveTasks: data["Max Active Tasks"] ? data["Max Active Tasks"] : 3,

                }, async () => {
                    members = await this.fetchMembers(familyID);
                    staff = await this.fetchStaff();
                    this.context.setStaffAndMembers(staff, members)
                    this.context.setMaxActiveTasks(this.state.maxActiveTasks)
                    this.setState({
                        members: members, staff: staff, hasFamilyDataLoaded: true

                    })

                })
            })
            .catch(error => {
                this.setState({hasFamilyDataLoaded: true})
                console.error(error);
            });

        return {assistantID, maxActiveTasks, members, staff};
    }

    fetchMemberData = (memberID) => {
        this.setState({
            hasMembersDataLoaded: false
        })

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                memberID: memberID
            }),
        };


        fetch(`${baseURL}/utils/fetchMemberData.php`, requestOptions)
            .then((response) => response.json())
            .then(data => {
                const memberEmail = data.Email;
                this.setState({memberEmail: memberEmail, hasMembersDataLoaded: true});

            })
            .catch(error => {
                this.setState({hasMembersDataLoaded: true})
                console.error('Error fetching member data:', error)
            });
    }





    saveItemsOrder = (items) => {
        const {familyID} = this.state;
        fetch(`${baseURL}/utils/saveOrder.php`, {
            method: "POST", body: JSON.stringify({familyID: familyID, items: items}),
        })
            .then((response) => response.text())
            .catch((error) => {
                console.error("There was an error saving the item order:", error);
            });
    };


    handleTaskClick = (task) => {
        if (!this.state.swapDialogOpen) {
            this.context.setChatTask(task)
            this.context.clearTaskBadges(task.id).then(() => {
                this.context.navigate('/chat');
            });
        }
    };

    // for when we do taskOverview
    // handleTaskClick = (task) => {
    //     if (!this.state.swapDialogOpen) {
    //         this.context.setChatTask(task)
    //         this.context.navigate('/taskOverview');    
    //     }
    // };


    handleTaskClickByID = async (taskID) => {

        if (taskID === 'General') {
            const dataObject = {
                id: 'General',
            };
            await this.context.setChatTask(dataObject)
            this.context.navigate('/chat');
            return;
        }

        if (taskID.startsWith('mem_')) {
            const dataObject = {
                id: taskID,
            };
            await this.context.setChatTask(dataObject)
            await this.context.setActiveGeneralChatTab('secret')
            this.context.navigate('/chat');
            return;
        }


        const {familyID} = this.state;

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, taskID: taskID, function: "fetchClientTask"
            }),
        };

        try {
            const response = await fetch(`${baseURL}/utils/fetchClientTask.php`, requestOptions);
            const jsonResponse = await response.json();

            const data = jsonResponse.record;

            if (data && data.fields) {

                if (data.fields["Research Status"] === "Done (Published)") {
                    data.recLink = await this.context.fetchSelection(taskID);
                }

                data.index = 0;
                data.isPinned = data.fields["Urgent Task"] && data.fields["Task - Status Text"]?.[0] !== "On Hold";
                data.isHold = data.fields["Task - Status Text"]?.[0] === "On Hold";

                await this.context.setChatTask(data);

                this.context.clearTaskBadges(taskID).then(() => {
                    this.context.navigate('/chat');
                });
            } else {
                console.log('No data.fields found in the response');
            }
        } catch (error) {
            console.error('Error:', error);
        }

    };

    handlePin = (taskId) => {
        this.context.handleTogglePin(taskId, true);
    };

    handleRemovePin = (taskId) => {
        this.context.handleTogglePin(taskId, false);
    };


    handleHold = (taskId) => {
        this.context.handleToggleHold(taskId, 1);
    };

    handleUnhold = (taskId) => {
        this.context.handleToggleHold(taskId, 0);
    };

    handleChildStateChange = (isErrorDialogOpen, taskIdToBePinned) => {
        this.setState({
            swapDialogOpen: isErrorDialogOpen, taskToBePinned: taskIdToBePinned
        });

        if (this.pinnedListRef) {
            const rect = this.pinnedListRef.getBoundingClientRect();
            this.setState({
                dialogPosition: {
                    top: rect.top + window.scrollY,
                    left: rect.left + window.scrollX,
                    width: rect.width,
                    height: rect.height
                }
            })
        }

    };


    getItemStyle = (draggableStyle) => ({
      
         ...draggableStyle,
    
    });


    calculatePinnedCount = (items) => {
        return items.filter(item => item.isPinned).length;
    }







    handleTabChange = (event, newValue) => {

        // this.setState(prevState => ({
        //     unreadMessageCount: {
        //         ...prevState.unreadMessageCount, 0: 0
        //     }
        // }));

        this.setState({tab: newValue});
        this.context.fetchTasks(this.state.tab).catch((error) => {
            console.error("Error fetching tasks:", error);
        });

    }
    renderChat() {
        const dataObject = {
            id: 'General',
        };
        this.context.setChatTask(dataObject)
        this.context.navigate('/chat');

}

handleLoginSuccess = (familyID, memberID) => {
    this.setState({ familyID, memberID, authenticated: true }, () => {
        this.performFamilyIDDependentActions(familyID);
        this.performAuthenticatedActions(familyID);
    });
};


    render() {
        const {
            authenticated,
            tab,
        } = this.state;

        if (!authenticated) {
            return (
                <Login onLoginSuccess={this.handleLoginSuccess}
                onLogout={this.logout}
                 />
            );
        } else {
            if (!this.context.hasTasksLoaded) {

                return (
                    <div style={{ textAlign: "center", margin: "50px auto" }}>
                        <CircularProgress style={{ color: '#1C1D1F' }} />
                    </div>
                );
            }

            return (
                <div>
                    <ThemeProvider theme={MUIStyles}>
                        <Box
                            ref={this.scrollableDivRef}
                            sx={{ overflow: 'auto', height: '100vh' }}
                            id="app-container"
                        >
                            {/* Bottom Navigation Menu */}
                            <Box
                                ref={this.bottomMenuRef}
                                sx={{
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    bgcolor: '#FFFFFF',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    zIndex: 1000,
                                    paddingBottom: `calc(env(safe-area-inset-bottom))`,
                                }}
                            >
                                <MyTabs
                                    handleTabChange={this.handleTabChange}
                                    activeTab={this.state.tab}
                                    unreadMessageCount={this.context.unreadMessageCount}
                                />
                            </Box>

                            {/* Main Content */}
                            <Grid container spacing={2} ref={this.gridContainerRef}>
                                <Grid item xs={12}>
                                    {tab === "for you" && (
                                        <ForYou
                                            members={this.state.members}
                                            memberID={this.state.memberID}
                                            getItemStyle={this.getItemStyle}
                                            handleTaskClick={this.handleTaskClick}
                                            handlePin={this.handlePin}
                                            handleRemovePin={this.handleRemovePin}
                                            handleHold={this.handleHold}
                                            handleUnhold={this.handleUnhold}
                                            handleChildStateChange={this.handleChildStateChange}
                                            calculatePinnedCount={this.calculatePinnedCount}
                                            maxActiveTasks={this.state.maxActiveTasks}
                                        />
                                    )}
                                      {tab === "tasks" && (
                                    <TaskPage
                                        onLogout={this.logout}
                                        tab={this.state.tab}
                                        newTask={this.newTask}
                                    />
                                )}
                                   
                                    {tab === "chat" && this.renderChat()}
                                    {tab === "account" && (
                                        <MyAccountPage
                                            onLogout={this.logout}
                                            tab={this.state.tab} />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </ThemeProvider>
                </div>
            );
        }
    }
}


const createBanner = () => {
    const banner = document.createElement('div');
    banner.style.position = "fixed";
    banner.style.top = "0";
    banner.style.left = "0";
    banner.style.width = "100%";
    banner.style.zIndex = "999998";
    return banner;
};

const adjustBodyMargin = (bannerHeight) => {
    document.body.style.marginTop = bannerHeight + 'px';
};

const createToolbar = () => {
    const toolbar = document.createElement('div');
    toolbar.style.display = "flex";
    toolbar.style.justifyContent = "space-between";
    toolbar.style.alignItems = "center";
    toolbar.style.backgroundColor = "#2F98A3";
    toolbar.style.color = "#ffffff";
    toolbar.style.padding = "10px";
    return toolbar;
};

const createText = (text) => {
    const span = document.createElement('span');
    span.innerText = text;
    span.style.flexGrow = "1";
    span.style.color = "#ffffff";
    span.style.fontFamily = "Manrope";
    span.style.fontWeight = "500";
    span.style.fontSize = "12px";
    span.style.paddingBottom = "12px";
    span.style.paddingTop = "12px";
    return span;
};

const createButton = (text) => {
    const button = document.createElement('button');
    button.innerText = text;
    button.style.backgroundColor = "#2F98A3";
    button.style.color = "#fff";
    button.style.border = "none";
    button.style.padding = "10px 20px";
    button.style.cursor = "pointer";
    button.style.fontFamily = "Manrope";
    button.style.fontSize = "12px";
    return button;
};

const subscribeToNotifications = async () => {
    const familyID = localStorage.getItem('familyID');
    const memberID = localStorage.getItem('memberID');
    const userAgent = navigator.userAgent;
    try {

        const readyRegistration = await navigator.serviceWorker.ready;
        const subscription = await readyRegistration.pushManager.getSubscription();
        if (!subscription) {
            const newSubscription = await readyRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BMDwOEUNSGgdzEzD_SMF0YcQ_8trBFArJShGxFo-mNhtMiOE-PIV1zXQD7S_4219deVwghAFLYcHnnIlCsCEEQc'
            });

            await fetch(`${baseURL}/utils/pushNotifications.php?action=subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `familyID=${familyID}&memberID=${memberID}&subscription=${JSON.stringify(newSubscription)}&userAgent=${encodeURIComponent(userAgent)}`,
            });
        }
    } catch (error) {
        console.error("Error in subscribeToNotifications:", error);

    }

};

const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {

            const registration = await navigator.serviceWorker.register('/service-worker.js?v=1_5_0');
            let refreshing = false;

            // Listen for updates to the service worker.
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (refreshing) return;
                window.location.reload();
                refreshing = true;
            });

            // Check if the new service worker is installed.
            if (registration.waiting) {
                registration.waiting.postMessage({type: 'SKIP_WAITING'});
            }

            const newWorker = registration.installing;
            if (newWorker) {
                newWorker.addEventListener('statechange', () => {
                    if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                        // A new service worker is available, post a message to it.
                        newWorker.postMessage({type: 'SKIP_WAITING'});
                    }
                });
            }

            const familyID = localStorage.getItem('familyID');
            const memberID = localStorage.getItem('memberID');
            const existingPermission = localStorage.getItem('notification-permission');

            if (familyID && memberID) {
                if (existingPermission === 'granted') {
                    await subscribeToNotifications();
                } else {
                    const enableButton = createButton("Allow");
                    const toolbar = createToolbar();
                    const text = createText("Enable BlckBx App Notifications?");
                    const banner = createBanner();

                    toolbar.appendChild(text);
                    toolbar.appendChild(enableButton);
                    banner.appendChild(toolbar);
                    document.body.appendChild(banner);
                    adjustBodyMargin(banner.offsetHeight);


                    enableButton.addEventListener("click", async () => {
                        banner.remove();
                        adjustBodyMargin(0);

                        const permission = await Notification.requestPermission();
                        if (permission === 'granted') {
                            localStorage.setItem('notification-permission', 'granted');
                            await subscribeToNotifications();
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Something went wrong during registration:', error);
        }
    } else {
        console.error("This browser does not support push notifications.");
    }
};

const main = async () => {
    try {
        await registerServiceWorker();
    } catch (error) {
        console.error('Service worker registration failed:', error);
    }
};

document.addEventListener('deviceready', onDeviceReady, false);

function onDeviceReady() {
    if (window.cordova) {
        setupUniversalLinks();
        startReactApp();
    }
}

function setupUniversalLinks() {
    universalLinks.subscribe('openChatRoute', onDeepLink.bind(this));
}

function onDeepLink(e) {

    const {url, path, params} = e;
    console.log("Deep link URL:", url);
    console.log("Path:", path);
    console.log("Parameters:", JSON.stringify(params, null, 2));

}

function startReactApp() {
    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(<RoutingApp/>);
}

if (!window.cordova) {
    startReactApp();

    main()
        .then(() => {
        })
        .catch((error) => {
            console.error('Main function failed:', error);
        });
}