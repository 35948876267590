import React from 'react';
import {Typography} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DownloadIcon from '@mui/icons-material/Download';

class PaymentDetailsSection extends React.Component {

    render() {
        const {isVisible, onToggle, itemId, receipt} = this.props;
        const isIconVisible = isVisible || false;

        const iconContainerStyle = {
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            transition: "transform 0.2s ease",
        };

        const iconStyle = { color: "#888888", width: 32, height: 32 };

        const IconComponent = isIconVisible ? (
            <KeyboardArrowUpIcon
                style={iconStyle}
                onClick={(event) => {
                    onToggle(event, itemId);
                    event.stopPropagation();
                }}
            />
        ) : (
            <KeyboardArrowDownIcon
                style={iconStyle}
                onClick={(event) => {
                    onToggle(event, itemId);
                    event.stopPropagation();
                }}
            />
        );

        const openReceiptInNewTab = (event) => {
            window.open(receipt, '_blank');
            event.stopPropagation();
        };

        return (
            <div>
                {isIconVisible && (
                    <div>
                        <Typography component="div" style={{display: "flex", alignItems: "center"}}>
                            <div style={{
                                paddingRight: 12,
                                marginLeft: "auto",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <div style={{
                                    color: '#2F98A3',
                                    fontSize: 14,
                                    fontFamily: 'Manrope',
                                    fontWeight: '500',
                                    wordWrap: 'break-word',
                                    cursor: 'pointer',
                                }} 
                                onClick={openReceiptInNewTab}>
                                    Receipt
                                </div>
                                <DownloadIcon style={{color: '#2F98A3', height: 14}}/>
                            </div>
                        </Typography>
                    </div>
                )}
                <div style={iconContainerStyle}>
                    {IconComponent}
                </div>
            </div>
        );
    }
}

export default PaymentDetailsSection;