import React from 'react';
import {Button, Dialog, DialogTitle, DialogActions, Box,} from '@mui/material';
import {AppContext} from './AppContext';

class TaskAddedSuccessDialog extends React.Component {
    static contextType = AppContext;

    handleBack = () => {
        setTimeout(() => {
            this.context.navigate('/#');
          }, 700);
    };

    render() {
        const {open, onClose} = this.props;


        return (<Dialog
            open={open}
            onClose={onClose}
            sx={{'& .MuiPaper-root': {backgroundColor: '#E8E5E0', color: '#1C1D1F', minWidth: '300px'}}}
        >
            <DialogTitle sx={{color: '#1C1D1F', fontSize: '16px', textAlign: 'center'}}>
            {this.props.isSuccess ? "We've got it!" : "Error!! Please try again later"}
            </DialogTitle>
            <DialogActions>
                <Box width="100%" display="flex" flexDirection="row" gap={2}>

                    <Button
                        onClick={this.handleBack}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#C0BDBD', color:'black', '&:hover': {
                                backgroundColor: '#C0BDBD',
                            }, textTransform: 'none'
                        }}
                    >
                        Go Back
                    </Button>

                    <Button
                        onClick={onClose}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#1C1D1F', '&:hover': {
                                backgroundColor: '#1C1D1F',
                            }, textTransform: 'none'
                        }}
                    >
                        Add New
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>);
    }
}

export default TaskAddedSuccessDialog;
